.iconContainer {
    width: 16px;
    height:16px;
    position: relative;
}
.centered {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
}
.profileImg {
    composes: centered;
    border: 0.5px solid #e6e6e6;
    object-fit: cover;
    transform: scale(0.9) translate(50%, 50%);
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    line-height: 22px;

    & > * {
        width: 120%;
        height: 120%;
        transform: translate(-10%, -30%);
    }
    & > svg {
        width: 80%;
        height: 80%;
        transform: translate(10%, -50%);
    }
}
.integrationAppIcon {
    composes: centered;
    line-height: 100%;

    svg {
        // background: white;
        border-radius: 3px;
    }
}
