@import 'styles/variables.scss';

.shortcut {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    // span {
    //     width: 100%;
    //     text-overflow: ellipsis;
    //     white-space: nowrap;
    //     word-wrap: break-word;
    // }
    // width: 100%;
    &:hover {
        color: $selected-100;
        .key {
            background-color: $black-5;
            border: solid 1px $selected-100;
        }
    }
}
.keys {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $black-60;
    & > * {
        margin-right: 5px;
    }
    div:last-child {
        margin-right: 0px;
    }
    .key {
        border: solid $black-40 1px;
        border-radius: 3px;
        // margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white-transparent-50;
        // box-shadow: 0px 1px 0.5px $black-10;
    }
    .singleCharKey {
        width: 35px;
        height: 35px;
    }
    .multiCharKey {
        padding: 0 7px;
        height: 35px;
    }
}
