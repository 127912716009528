@import 'styles/variables';

.baseBottomBar {
    z-index: 10;
    // position: fixed;
    left: 0;
    bottom: 0;
    background: $black-3;
    border-top: 1px solid $black-5-t;
    border-right: 1px solid $black-5-t;
    display: flex;
    height: 32px;
    padding: 3px 7px 3px 16px;
    padding: 8px 7px 8px 16px;
    align-items: center;
    justify-content: space-between;
}

.leftBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.bottomBarIcon {
    cursor: pointer;
    padding: 0 2px;
    svg {
        height: 16px;
        width: 16px;
        transform: translateY(-1px);
    }
    img {
        height: 16px;
        width: 16px;
        transform: translateY(-1px);
    }
}

.greyFill {
    svg {
        path {
            fill: $black-30;
        }
    }
    &:hover {
        svg {
            path {
                fill: $black-80;
            }
        }
    }
}
.greyStroke {
    svg {
        path {
            stroke: $black-30;
        }
    }
    &:hover {
        svg {
            path {
                stroke: $black-80;
            }
        }
    }
}

.bottomBarAvatar {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    overflow: hidden;
    background: white;
    color: white;
    font-size: 10px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-1px);
}

.bottomBarSeparator {
    font-size: 16px;
    color: $black-30;
    line-height: 20px;
    transform: translateY(-1px);
}
