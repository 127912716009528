@import 'styles/variables';

.tippy-box {
    border: none;
    box-shadow: 0px 5px 30px 0px rgba(0,0,0,0.15);
    max-width: 500px !important;
    border-radius: 4px;
}

.tippy-arrow::before {
    display: none;
}

.tippy-content {
    padding: 0 !important;
}

.tippyMenuItem {
    color: black;
    padding: 5px 10px;
    &:hover {
        color: white;
        background-color: $selected-100;
        cursor: pointer;
    }
}

.drivePlusButton {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 2px;
    width: 29px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: $selected-30;
    }
    &.active {
        background-color: $selected-60;
        color: white;
    }
}
