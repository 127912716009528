.aboutBedrockModal {
    text-align: center;
}
.scrollContainer {
    border: 1px solid black;
    height: 350px;
    overflow: hidden;
    @font-face {
        font-family: 'Sysfont';
        src: url('/fonts/sysfont/sysfont.woff2') format('woff2'),
        url('/fonts/sysfont/sysfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    p {
        font-family: 'Sysfont' !important;
    }
}

.headshot {
    max-width: 400px;
    width: 100%;
    margin-bottom: 60px;
}

.crawl {
    position: relative;
    top: 800px;
    transform-origin: 50% 100%;
    animation: crawl 25s linear;
}

@keyframes crawl {
    0% {
      top: 0;
    }
    100% { 
      top: -2800px;
    }
}

.floatingSymbol {
    position: absolute;
    top: -50px;
    width: 100%;
    padding-right: 95px;
    filter: drop-shadow(0px 0.389732px 5.06651px rgba(255, 255, 255, 0.55)) drop-shadow(0px 0.389732px 3.89732px rgba(139, 227, 255, 0.65));
    img {
        width: 30px;
    }
}