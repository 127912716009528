@import 'styles/variables';

.container {
    padding: 10px 20px;
}

.loadSpinner {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 40px;
}

.loadSpinner div {
    position: absolute;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: $selected-100;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loadSpinner div:nth-child(1) {
    left: 8px;
    animation: loadSpinner1 0.6s infinite;
}

.loadSpinner div:nth-child(2) {
    left: 8px;
    animation: loadSpinner2 0.6s infinite;
}

.loadSpinner div:nth-child(3) {
    left: 32px;
    animation: loadSpinner2 0.6s infinite;
}

.loadSpinner div:nth-child(4) {
    left: 56px;
    animation: loadSpinner3 0.6s infinite;
}

@keyframes loadSpinner1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes loadSpinner3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes loadSpinner2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

.header {
    display: flex;
    justify-content: space-between;
}