@import "styles/variables.scss";

.saveButton {
    box-shadow: none !important;
    &.fullButton {
        margin-left: 6px;
        width: auto !important;
        padding: 0 10px !important;
        background-color: $selected-100;
        border: none;
        &:focus, &:active {
            background-color: $selected-80 !important;
        }
    }
}