.thumbnailWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
}

.thumbnailImage {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

