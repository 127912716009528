@import 'styles/variables';

.container {
    width: 100%;
    height: 100%;
}

.position {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    overflow: hidden;
    padding: 0 8px;
}

.box {
    width: 174px;
    height: 174px;

    margin: 0 auto 10px;

    border: 5px dashed $black-10;
    border-radius: 21.75px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding: 10px; 
    & > div {
        width: 100%;
        height: 100%;
        
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 12px;
        background-color: $black-5;

    }
}

.boxHovered {
    border: 5px dashed $selected-100;
}

.details {
    margin: 10px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 360px;
}

.title {
    width: 100%;
    &.withIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
    }
}
.hlistMetadata {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // max-width: 220px;
    min-width: 220px;

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid $black-10;
        
        &:last-child {
            border-bottom: none;
        }
        // margin: 5px 0;
        .label {
            font-size: 12px;
            font-weight: 500;
            color: $black-50;
            margin-right: 5px;
        }
        .value {
            font-size: 12px;
            font-weight: 500;
            // color: $black-100;
        }
    }
}

.previewTopBar {
    // height: 32px;
    // border-bottom: 1px solid $black-10;
    padding: 4px 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.galleryContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    grid-template-rows: repeat(auto-fit, 250px);
    grid-gap: 40px;
    padding: 10px;
    width: 100%;
    max-width: 1160px;
    margin: 5vh auto 0;
    justify-content: center;
    align-items: center;
}

.galleryItem {
    overflow: hidden;
    object-fit: cover;
    width: 250px;
    height: 250px;
    border: 1px solid $black-10 !important;
    position: relative;

    &:hover {
        .metadata {
            opacity: 1;
        }
    }
}

.actualImage {
    cursor: pointer;
    // .metadata {
    //     opacity: 0;
    // }
}

.metadata {
    opacity: 0;

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.15s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: $black-10-t;
    backdrop-filter: blur(6px);
}

.metadataRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;

    max-width: 80%;
    height: 32px;
    padding: 0 10px;
    background-color: white;
    border-radius: 6px;
}
.metadataIcon {
    border-radius: 6px;
}
.metadataTitle {
    text-align: center;
    // max-width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    white-space: nowrap;
    font-size: 13px;
    line-height: 13px;
}

.image {
    width: 250px;
    height: 250px;
    object-fit: cover;
    position: absolute;

    &::before {
        content: '';
        width: 100%;
        height: calc(100% + 10px);
        background-color: #ccc;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        left: -2px;
        transform: translateY(-50%);
    }

    &::after {
        content: "Not Found";
        font-size: 18px;
        color: rgb(100, 100, 100);
        display: block;
        position: absolute;
        z-index: 2;
        top: 5px;
        left: 0;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        top: 50%;
        z-index: 0;
        font-size: 13px;
   }
}

.imageIcon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    // svg, img {
    //     width: 40%;
    //     height: 40%;
    // }
}

.previewTopBarConntainer {
    top: 0;
    position: sticky;
    background-color: white;
    z-index: 10;
}
