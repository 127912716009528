@import 'styles/variables';

.imageGrid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    margin: 0 auto;
}

.imageGridItemSelectionWrapper {
    padding: 8px;
    border-radius: 5px;
    &:hover {
        background-color: $black-5;  
    }
}
.selected {
    box-shadow: inset 0 0 0 3px $selected-100;
}
.imageGridItem {
    background-color: $black-10;
    border: 0.5px solid $black-10;
    width: 200px;
    height: 130px;
    border-radius: 3px;
    overflow: hidden;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;        
    }
    &:hover {
        background-color: $black-20;
    }
}
