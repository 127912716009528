@import '../../../styles/variables';

.aboutAppModal {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 350px;
}

.appHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.appIcon {
    height: 70px;
    width: 70px;
    svg {
        width: inherit;
        height: inherit;
        border-radius: 20%;
        box-shadow: $elevation--icon;
    }
}

.appTitle {
    margin: 0;
}
