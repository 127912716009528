
@import 'styles/variables';

.globalContainer {
    z-index: 1000;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.backdrop {
    /* modal stuff */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;

    &.blur {
        background-color: rgba(0, 42, 106, 0.1);
        backdrop-filter: blur(80px);
        -webkit-backdrop-filter: blur(80px);

        &.fade {
            animation: slowBlur 0.5s ease;
        }
    }

    &.darken {
        background-color: rgba(28, 34, 43, 0.753);
    }
}

.modal {
    position: absolute;
    margin: auto;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: $elevation--high;
    background-color: $white;

    &.fade {
        animation: fade 1s ease;
    }
}

.hide {
    animation: fadeOut 0.3s ease-out;
    animation-fill-mode: forwards;
}

.transparentModal {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
}

.top30 {
    top: 30%;
}
.fullWidth {
    width: 100%;
}

@keyframes fadeOut {
    0% { opacity: 1 }
    100% { opacity: 0 }
}


@keyframes fade {
    0% { opacity: 0 }
    50% { opacity: 0 }
    100% { opacity: 1 }
}

@keyframes slowBlur {
    0% { 
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        background-color: rgba(0, 42, 106, 0);
        opacity: 0;
    }
    100% {
        opacity: 1;
        backdrop-filter: blur(80px);
        -webkit-backdrop-filter: blur(80px);
        background-color: rgba(0, 42, 106, 0.1);
    }
}