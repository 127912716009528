@import '../../../styles/variables';
.sendFeedbackPopup {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 90vw;
    background: #FFFFFF;
    box-shadow: $elevation--high;
    border-radius: 10px;
    box-sizing: border-box;
}

.popupHeaderSection {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    border-bottom: solid 1px $black-10;

}
.popupHeader {
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
}
.popupSubHeader {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $black-50;
}
.popupError {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $error;
}
.popupBodySection {
    padding: 24px;
}
.feedbackInputArea {
    min-height: 160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    caret-color: $selected-100;
    textarea {
        flex-grow: 1;
        height: 100%;
        border: none;
        resize: none;
    }
}
.feedbackPopupActions {
    display: flex;
    justify-content: space-between;
    gap: 40px;
}
.attachmentsDropzone {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-height: 50px;
    border: none;
}
.attachmentsDropzone_active {
    border: 1px dashed $success;
    * {
        opacity: 0.5;
    }
}
.attachmentLabel {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}

.submitButton {
    min-width: 124px;
}

.imageGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 10px;
    max-width: 500px;
    max-height: 100px;
    overflow-y: auto;
}

.gridItem {
    position: relative;
    width: 80px;
    height: 80px;
    img {
        object-fit: cover;
        width: 80px;
        height: 80px;
    }
}

.removeButton {
    position: absolute;
    top: 0;
    right: 0;
}

@media (max-width: 768px) {
    .feedbackPopupActions {
        flex-direction: column;
        gap: 10px;
    }

    .submitButton {
        min-width: unset;
        max-width: 124px;
        margin-left: auto;
    }
}
