@import "styles/variables.scss";

.userAccountMainContainer {
    margin: -30px;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 30px;
    border-bottom: 1px solid $black-10-t;
}
.avatarNameContainer {
    display: flex;
    .avatarButtonContainer {
        margin-right: 10px;
    }
}

.comingSoon {
    &::before {
        content: 'Coming Soon...';
        padding: 5px 10px;
        background: $black-10-t;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        border-radius: 4px;
    }
    &::after {
        content: ' ';
        // background-color: yellow;
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    position: relative;
    // padding: 24px;
    width: 100%;
    height: 100%;
}
