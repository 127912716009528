@import 'styles/bootstrap-override.scss';
@import 'styles/styleguide.scss';
@import 'styles/variables';
@import 'styles/forms';
@import 'styles/quill';
@import 'styles/fonts';
@import 'styles/tippy';
@import 'node_modules/react-quill/dist/quill.bubble';

.mention-items {
    padding: 5px;
    border-radius: 6px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}
.mention-item {
    display: flex;
    align-items: center;
    border-radius: 5px;
    // margin-bottom:2px;
}
.mention-item div {
    margin-right: 6px;
    margin-top: 2px;
}
.app {
    height: 100vh;
}

menu {
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
}

.hide-obsoletes .obsolete {
    display: none;
}

header {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;

    z-index: 9;

    background-color: $black-100;

    height: 40px;
    box-sizing: border-box;

    h1 {
        font-family: Helvetica, sans-serif;
        font-weight: 400;
        color: lightgray;
        margin: 0px;
        line-height: 0.4em;
        font-size: 20px;
    }

    a img {
        height: 25px;
        margin-right: 10px;
    }
}

#__next > div:focus {
    outline: none;
}

.warning a {
    text-decoration: none;
}

.dropzone {
    border: 1px dashed $secondary;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
}

.dropzone p {
    color: $secondary;
}

.backdrop {
    /* modal stuff */
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(28, 34, 43, 0.753)
}

.modal {
    box-shadow: $elevation--high;
    border-radius: 10px;
    box-sizing: border-box;
}
.modal-old {
    margin: 0 10px;
    // width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 50px 30px;
    gap: 30px;
    position: relative;
    background: #FFFFFF;
    box-shadow: $elevation--high;
    border-radius: 10px;
    max-height: 90vh;
    overflow-y: auto;
    // box-sizing: border-box;
    @media (max-width: 900px) {
        padding: 30px 20px 30px;
    }
}

input[type="submit"] {
    height: 50px;
    width: 100%;
}

input[type="radio"] {
    display: none;
}

.sortable-check-item {
    display: flex;
    align-items: center;
}

.editor-container {
    width: 600px;
    height: 400px;
    padding: 0px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: right;
    overflow-y: scroll;
}

.react-json-view {
    height: 350px;
}

.pretty-json-container {
    font-family: monospace;
}

.pdf {
    width: 80%;
    height: 500px;
}

code {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}

.img {
    max-width: 90%;
}

button:hover, button:focus {
    outline: none;
}

.chip {
    background-color: lightgrey;
    font-size: 0.8em;
    border-radius: 0.3em;
    display: inline-block;
    margin-right: 0.5em;
    padding: 0.5em;
}

aside {
    padding: 1em;
}

.entity > span {
    max-width: 100%;
    word-wrap: break-word;
}

ul {
    padding-inline-start: initial;
}


// h4 {
//     margin: 0px;
//     margin-bottom: 1em;
// }

.entity-item-list.compact {

    flex: 1;
    overflow: visible;
    margin-block-start: 0px;
    max-height: 100%;

    .entity {
        border: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;

    }

    details > div {
        padding-left: 5px;
    }

    :focus {
        outline: none;
    }
}


.file-browser > .entity-item-list {
    border-right:  1px solid lightgray;

    .entity div, .entity details span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
    }

}

.entity.compact {
    margin: 0px;
    padding: 0px;
}

aside {
    background-color: lightgray;
    border-radius: 1em;
}


.side-popup {
    position: fixed;
    right: 0px;
    bottom: 0px;
    background-color: white;
    box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.3);
    z-index: 10;

    padding: 10px;
}

.toolbar .dropdown {
    .x-button {
        width: 1.3em;
        text-align: center;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 10;
    }

    .x-button:hover {
        background-color: transparent;
        color: red;
    }
}

.index {
    height: 100%;
}

.index > .entity {
    margin: 0px;
    padding: 0px;
    height: inherit;

    display: flex;

}

.entity-item-list.compact {
    .entity {
        padding: 0px;
        margin: 0px;
    }
}

.blob-add-button, .kv-add-button {
    position: absolute;
    top: 0px;
    right: 0px;
}

.kv-rm-button {
    position: absolute;
    top: 0px;
    right: 30px;
}

.blob-form, .kv-form {
    position: relative;
    padding: 15px 0px;
}

.fake-controlled-field {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.query-filter {
    display: flex;
    flex-direction: column;
}

[class*="-icon-button"], [class*="IconButton"] {
    color: grey;
    border: none;
    background-color: transparent;
    padding: 0px;
    margin-left: 10px;
    line-height: 1em;
}

.appIconMini {
    svg{
        width: 15px;
        height: 15px;
        border-radius: 2px;
        box-shadow: 0px 1px 2px 0.25px rgb(0 0 0 / 30%);
        margin-bottom: 2px;
    }
}

// Styles for System Menu app icons.
// Designed to match WindowFrame currentApp styles
.appIconStyles {
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0.25px rgb(0 0 0 / 30%);
    margin: 0px;
    width: 20px;
    height: 20px;
    display: flex;
    & > svg {
        width: 20px;
        height: 20px;
    }
}

.react-switch-bg {
    box-shadow: inset 0px 3px 8px rgba(0, 0, 0, 0.15);
}


.g-dropdown-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.g-dropdown-icon-button {
    border: none;
    background: none !important;
    height: 23px;
    width: 23px;
    line-height: 20px;
    box-sizing: border-box;
    padding: 0px;
    margin-left: 6px;
    color: $black-40;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;
    cursor: pointer;

    &::after {
        display: none;
    }

    svg {
        width: 15px;
        height: 15px;
        * {
            fill: $black-40;
        }
    }

    .svg {
        margin: 3px;
        height: 18px;
    }

    .svg:hover {
        color: $secondary;
    }
}

.g-dropdown-icon-button:hover {
    background: $gray-secondary !important;
    color: rgba(0,0,0,0.4) !important;
}

[class$="-icon-button"]:hover {
    color: $secondary;
    background-color: transparent;
}

.close-icon-button {
    padding-right: 10px;
}

.var-module {
    display: flex;
    justify-content: space-between;
}


[class$="-module"] {
    position: relative;
}


.corner-buttons {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
}

.edit-icon-button {
    transform: rotateY(180);
}

.react-grid-item {
    .entity {
        padding: 0px;
        margin: 0px;
        overflow: none;
    }

}

.ql-tooltip {
    z-index: 5;
}

img.chiplet {
    margin-right: 3px;
}

.summary {
    span {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        display: flex;
        align-items: center;
    }
}

.controlMenuButton {
    height: 22px;
    display: flex;
    align-items: center;
    -webkit-app-region: no-drag;
}

summary {
    display: flex;
    align-items: center;
    outline: none;

    .listItemMenuBtn {
        visibility: hidden;
        &::after {
            display: none;
        }
    }

    &:hover {
        .listItemMenuBtn {
            visibility: visible;
        }
    }
}

.hover-container {
    position: relative;
    .hover-child {
        visibility: hidden;
    }

    &:hover {
        .hover-child {
            visibility: visible;
        }

        .hover-container {
            .hover-child {
                visibility: hidden;
            }

            &:hover {
                .hover-child {
                    visibility: visible;
                }
            }
        }
    }
}

.expand-button {
    .expand-text {
        display: none;
    }
    &:hover {
        .expand-text {
            display: inline;
        }
    }
}

.grabbed {
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.35);
};

// h2 {

//     font-size: 30px;
//     font-weight: normal;
//     margin-block-end: 0.3em;

//     a {
//         text-decoration: none;
//         color: black;
//         font-weight: bold;
//     }

// }

.dim {
    color: darkgrey;
}

.typefield {
    margin-bottom: 1em;
}

.entity-item-list {
    h2 {
        font-size: 18px;
    }
}

.quill {
    width: 100%;
}

.splitter-layout {
    // position: absolute;
    display: flex;
    gap: 6px;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
    overflow: auto;
  }
  
  .splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
  }
  
  .splitter-layout > .layout-splitter {
    flex: 0 0 auto;
    width: 4px;
    height: 100%;
    cursor: col-resize;
  }
  
  .splitter-layout .layout-splitter:hover {
// none  
}
  
  .splitter-layout.layout-changing {
    cursor: col-resize;
  }
  
  .splitter-layout.layout-changing > .layout-splitter {
    //none
  }
  
  .splitter-layout.splitter-layout-vertical {
    flex-direction: column;
  }
  
  .splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
  }
  
  .splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 4px;
    cursor: row-resize;
  }
.layout-splitter {
    position: relative;
	z-index: 2;
    cursor: col-resize;
	top: 0;
	width: 0px;
	height: 100%;
    background: transparent;
    &::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 100%;
        left: -50%;
        transform: translateX(-50%);
    }
    &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        background: $white;
        top: 47.5%;
        height: 5%;
        width: 2px;
        opacity: 0.5;
	    left: 50%;
        transition: 0.25s linear;
        border-radius: 2px;
        transform: translateX(-50%);
    }
    &:hover
    {
        &::after {
            opacity: 0.8;
        }
    }
}

.base-splitter-layout {
    gap: 0;
    >div:first-child:not(:only-child) {
        border-right: 1px solid $black-10;
    }
}

.base-layout-splitter {
    composes: layout-splitter;
    &::after {
        background: $selected-100;
        top: 0;
        height: 100%;
        width: 4px;
        opacity: 0;
    }
    &:hover {
        &::after {
            opacity: 1;
        }
    }
}

.no-separator {
    gap: 0;
}

.toggle-hlist {
    visibility: hidden;
    z-index: 10;
    position: absolute;
    top: 45%;
    left: -10px;
    transition: visibility 0.1s ease-out;
    transition-delay: .5s;
}

.layout-splitter:hover .toggle-hlist {
    visibility: visible;
}

.splitter-vertical-wrapper {
    width: 100%;
    height: 100%;
}

.base-splitter-vertical-wrapper {
    width: 100%;
    height: calc(100% - $base-header-height);
}

.splitter-vertical .layout-pane {
  overflow: hidden;
}

.focused {
    box-shadow: inset 0 0 0 1px $selected-100;
}

.bs-popover-bottom > .arrow {
  display: none;
}

.bs-popover-right > .arrow {
  display: none;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* This gives the remote user caret. The colors are automatically overwritten*/
.ProseMirror-yjs-cursor {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-color: orange;
    word-break: normal;
    pointer-events: none;
}
/* This renders the username above the caret */
.ProseMirror-yjs-cursor > div {
    position: absolute;
    top: -1.05em;
    left: -1px;
    font-size: 10px;
    background-color: rgb(250, 129, 0);
    font-weight: normal;
    line-height: normal;
    user-select: none;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
    white-space: nowrap;
    width: auto !important;
}

.disabled {
    pointer-events:none; 
    opacity:0.6;
}

.loadingImg {
    width: 45px;
    height: 45px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

div:focus {
    outline: none;
}

.waitlistMessageContainer {
    height: 100vh;
    width: 100vw;
    .waitlistMessage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;

        svg {
            margin-top: 20px;
        }
    }
}

.terms-privacy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px;

    ol {
        padding-left: 20px;
    }
    li {
        margin-bottom: 10px;
    }
    h3 {
        font-size: 20px;
        margin-top: 20px;
        line-height: 1;
        text-decoration: underline;
    }
    h4 {
        font-size: 16px;
    }
}

#entity-tabs-content {
    height: 100%;
}

#entity-tabs-content > #entity-container-consumer > div {
    height: calc(100% - 32px) !important;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.whiteIcon path {
    fill: #FFFFFF;
}

.blackIcon path {
    fill: #000000;
}
