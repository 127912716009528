@import 'styles/variables';

.avatarUploadContainer {
    // todo
    width: 100%;
}

.avatarUpload {
    background-color: transparent;
    border-radius: 10px;
    border: none;
    color: $black-100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 0px;
    transition: all 0.2s;

    &:hover {
        background-color: $black-5;
    }
}
.avatarUploadLabel {
    margin-top: 10px;
    margin-bottom: 10px;
}

.avatarUploadLeft {
    composes: avatarUpload;
    margin: 0;
}

.avatar {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.icon {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10%;
}

.placeholderContainer {
    opacity: 0.1;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 80%;
        height: 80%;
    }
}

.imageCropper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
}

.imageCropperSettings {
    composes: imageCropper;
    width: 100px;
    height: 100px;
}

.iconCropper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    position: relative;
    overflow: hidden;
    border-radius: 10%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
}

.iconCropperSettings {
    composes: iconCropper;
    width: 100px;
    height: 100px;
}
