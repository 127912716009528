.stepContianer {
    width: 100%;
    height: 100%;

    border-radius: 16px 16px 0px 0px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.step {
    width: 100%;
    height: 100%;
}

.headerContainer {
    h2 {
        color: var(--text-primary, rgba(0, 0, 0, 0.85));
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: SF Pro Display, SF Pro Text, Helvetica Neue, Helvetica, Inter, sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 590;
        line-height: 110%; /* 39.6px */

        margin-bottom: 8px;
    }

    div {
        color: var(--text-secondary, rgba(0, 0, 0, 0.50));
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: SF Pro Display, SF Pro Text, Helvetica Neue, Helvetica, Inter, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 510;
        line-height: 110%; /* 22px */
    }
}

.stickyStepControl {
    position: sticky;
    bottom: 0;
    background: white;
    padding: 20px 0 20px;
}

.stepControlContainer {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.backButton {
    transition: all 0.2s ease-in-out;

    color: var(--text-secondary, rgba(0, 0, 0, 0.50));
    border: none;
    background-color: #ffffff00;
    border-radius: 12px;

    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    outline: 1.5px solid var(--selected-100, #4BA0F900) !important;

    &:hover {
        outline: 1.5px solid var(--selected-100, #4BA0F900) !important;
        background-color: #f0f0f0;
        color: var(--text-primary, rgba(0, 0, 0, 0.85));
        svg {
            opacity: 0.85;
        }
    }

    &:active {
        background-color: #e5e5e5;
        color: var(--text-primary, rgba(0, 0, 0, 0.85));
        svg {
            opacity: 0.85;
        }
    }

    &:focus {
        color: var(--text-primary, rgba(0, 0, 0, 0.85));
        svg {
            opacity: 0.85;
        }
        outline: 1.5px solid var(--selected-100, #4BA0F9) !important;
    }

    svg {
        transform: rotate(180deg);
        opacity: 0.5;
    }
}

.continueButton {
    transition: box-shadow 0.2s ease-in-out;
    width: 394px;

    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    border-radius: 12px;
    border: 0.75px solid #F0F0F0;
    background: #FFF;
    box-shadow: 0px 1.5px 6px 0px rgba(0, 0, 0, 0.12);

    outline: none !important;

    &:hover {
        border: 0.75px solid #F0F0F0;
        box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08) !important;
    }

    &:active {
        border: 0.75px solid #BFBFBF;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15) !important;
    }

    &:focus {
        border: 0.75px solid #BFBFBF;
        outline: 1.5px solid var(--selected-100, #4BA0F9) !important;
    }
}

.personasMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    height: 100%;
}

.personasContentContainer {
    max-width: 988px;
    max-height: 554px;
    width: 100%;
    height: 100%;
    overflow: auto;

    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 24px;

    border-radius: 12px;
    background: var(--fill-group, #FAFAFA);
}

.personaList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}
.personaButton {
    transition: all 0.2s ease-in-out;

    display: flex;
    width: 170px;
    padding: 16px;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;

    outline: 1.5px solid var(--selected-100, #4b9ff900);
    outline-offset: 0px;

    border-radius: 8px;
    border: 0.5px solid #BFBFBF;
    background: var(--fill-black-5, #F5F5F5);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 0px 0px rgba(0, 0, 0, 0.05);

    color: var(--text-secondary, rgba(0, 0, 0, 0.50));
    font-feature-settings: 'clig' off, 'liga' off;

    /* Bold/Title 2 */
    font-family: SF Pro Display, SF Pro Text, Helvetica Neue, Helvetica, Inter, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 590;
    line-height: 22px; /* 129.412% */
    letter-spacing: -0.17px;

    &[data-active="true"] {
        outline: 1.5px solid var(--selected-100, #4BA0F9) !important;
        outline-offset: 5px;
        border: 0.5px solid #BFBFBF;
        background: var(--fill-white, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 0px 0px rgba(0, 0, 0, 0.05);

        color: var(--text-primary, rgba(0, 0, 0, 0.85));
        font-feature-settings: 'clig' off, 'liga' off;

        /* Bold/Title 2 */
        font-family: SF Pro Display, SF Pro Text, Helvetica Neue, Helvetica, Inter, sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 590;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.17px;
    }

    &:hover {
        border: 0.5px solid #BFBFBF;
        background: var(--fill-white, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
    }

    &:focus {
        outline: 1.5px solid var(--selected-100, #4BA0F9) !important;
        outline-offset: 5px;
        border: 0.5px solid #BFBFBF;
        // background: var(--fill-black-5, #F5F5F5);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
    }
}

.personaDescription {
    position: relative;
    height: 100%;
    max-width: 925px;
    width: 100%;

    .personaImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        

        border-radius: 12px;
        opacity: 0;

        &[data-active="true"] {
            opacity: 1;
        }
    }
}


.textField {
    display: flex;
    padding: 22px 18px;
    align-items: center;
    gap: 1px;
    align-self: stretch;

    border-radius: 12px;
    // border: 1.5px solid var(--color-blue, #007AFF);
    border: 0.5px solid #BFBFBF;
    background: var(--fill-black-5, #F5F5F5);

    // color: #8B979C;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Medium/Title 2 */
    font-family: SF Pro Display, SF Pro Text, Helvetica Neue, Helvetica, Inter, sans-serif;
    font-size: 17px !important;
    font-style: normal;
    font-weight: 510;
    line-height: 22px; /* 129.412% */
    letter-spacing: -0.17px;

    &:focus, &:active, &:hover {
        border: 0.5px solid #bfbfbf00;
        outline: 1.5px solid var(--color-blue, #007AFF) !important;
        // border: 0.5px solid #BFBFBF;
    }
}
