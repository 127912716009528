@import 'styles/variables.scss';
@import url('https://rsms.me/inter/inter.css');

@supports (font-variation-settings: normal) {
    html {
        font-family: 'Inter var', sans-serif;
    }
}

@font-face {
    font-family: 'Sysfont';
    src: url('/fonts/sysfont/sysfont.woff2') format('woff2'),
    url('/fonts/sysfont/sysfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.classicSystemFont{
    font-family: 'Sysfont';
}

html {
    font-family: SF Pro, Inter, Helvetica, sans-serif;
}

body {
    font-family: SF Pro, Inter, Helvetica, sans-serif;
    color: $text;
    font-size: 16px;
    line-height: 26px;
    background: var(--electronBackground);
}

h1 {
    font-family: SF Pro, Inter, Helvetica, sans-serif;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.24;
}

h2 {
      font-size: 28px;
      line-height: 1.33;
      font-weight: 700;
      margin-bottom: 12px;
      margin-top: 12px;
}

h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 6px;
}

h4 {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.44;
}

h5 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
}

h6 {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.53;
}

li, ul, ol, dl {
    margin-bottom: 0;
}

.secondary {
    color: $black-40;
}

.secondaryText {
    color: $black-40;
}
.labelSecondary {
    color: $black-50;
    font-size: 12px;
}

iframe {
    border: 0;
}

a:hover {
    text-decoration: none;
}

select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;

    // Stack above custom arrow
    z-index: 1;

    // Remove dropdown arrow in IE10 & IE11
    // @link https://www.filamentgroup.com/lab/select-css.html
    &::-ms-expand {
      display: none;
    }

    // Remove focus outline, will add on alternate element
    outline: none;
  }

  .select {
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;

    select,
    &::after {
      grid-area: select;
    }

    min-width: 15ch;
    max-width: 30ch;

    border: 1px solid $black-10;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;

    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;

    // Optional styles
    // remove for transparency
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

    // Custom arrow
    &:not(.select--multiple)::after {
      content: "";
      justify-self: end;
      width: 0.8em;
      height: 0.5em;
      background-color: $black-60;
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    }
  }

  // Interim solution until :focus-within has better support
  select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid $selected-100;
    border-radius: inherit;
  }

  select[multiple] {
    padding-right: 0;

    /*
     * Safari will not reveal an option
     * unless the select height has room to
     * show all of it
     * Firefox and Chrome allow showing
     * a partial option
     */
    height: 6rem;

    option {
      white-space: normal;

      // Only affects Chrome
      outline-color: $selected-100;
    }

    /*
     * Experimental - styling of selected options
     * in the multiselect
     * Not supported crossbrowser
     */
    //   &:not(:disabled) option {
    //     border-radius: 12px;
    //     transition: 120ms all ease-in;

    //     &:checked {
    //       background: linear-gradient(hsl(242, 61%, 76%), hsl(242, 61%, 71%));
    //       padding-left: 0.5em;
    //       color: black !important;
    //     }
    //   }
  }

.menu {
    padding: 10px 0;
    box-shadow: 0px 2.5px 15px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 4px;
    min-width: 200px;
    .item {
        padding: 4px 15px;
        font-size: 13px;
        line-height: 21px;
        display: flex;
        color: $text;

        div:first-child {
            width: 16px;
            margin-right: 10px;
            display: flex;
            align-self: center;
            text-align: center;
            justify-self: center;
        }

        div:last-child {
            width: 80%;
            max-width: 200px;
            text-overflow: ellipsis;
            /* white-space: nowrap; */
            overflow: hidden;
            display: block;
        }

        &:active {
            color: $text;
            text-decoration: none;
            background-color: $white;
        }
        &:hover {
            color: $white;
            text-decoration: none;
            background-color: $selected-100;

            rect[stroke] {
                stroke: white;
            }
            path[fill] {
                fill: white;
            }
            path[stroke] {
                stroke: white;
            }
            path[fill="white"] {
                fill: $selected-100;
            }
        }
    }
    .menuDivider {
        height: 1px;
        border-bottom: 1px solid $black-10;
        margin: 10px 0;
    }
}
.dividerLine {
    display: block;
    height: 1px;
    border-bottom: solid 1px $black-10;
    margin-top: 15px;
    margin-bottom: 15px;
}
.appDropdown {
    transform: translate(0px, 29px) !important;
    min-width: 250px;
    max-width: 300px;
    overflow-y: auto;
}
.systemDropdown {
    transform: translate(0px, 29px) !important;
}

.baseSystemDropdown {
    transform: translate(-15px, -29px) !important;
}

.menuItem {
    display: flex;
    justify-content: space-between;
}
.menuItemMessage {
    float: right;
    color: $success;
    opacity: .5;
}
.secondaryLabel {
    color: $black-40;
}

.activeApp {
    width: 5px !important;
    height: 20px;
    background-color: $selected-100;
    position: absolute;
    left: 0px;
}

.addForm {
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.container {
    text-align: left;
    font-size: 14px;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.buttonRow {
    text-align: right;
    margin-top: 30px;
}

.formGroup {
    // margin-top: 30px;
    margin-bottom: 12px;
}

.formLabel {
    padding: 3px 10px;
    margin: 0;
    /* Black 40% - Transparent */
    color: rgba(0, 0, 0, 0.4);
}

/**********************************************/
/* ***** FORMS ****************************** */
/* Form Input - DEFAULT */
.formControl {
    border: none;
    box-sizing: border-box;
    font-size: 14px;
    padding: 3px 8px;
    /* Black 3% (Input Default) */
    background: #F7F7F7;
    border-radius: 4px;
    &:hover:not(:focus) {
        /* Black 5% (Drag zone, Input hover) */
        background: #F2F2F2;
    }
    &:focus {
        /* Selected 100% */
        box-shadow: inset 0 0 0 2px #00A0F7 !important;
        box-sizing: border-box;
        border-radius: 4px;
    }
    &::placeholder {
        color: #d5d5d5;
    }
    &.small {
        font-size: 12px;
        line-height: 20px;
    }
}

/**********************************************/
/* ***** BUTTONS **************************** */
/**********************************************/

.textButtonContainer {
    transition: background 20ms ease-in 0s;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    white-space: nowrap;
    border-radius: 3px;
    padding: 0 5px;
    &:hover {
        background: $black-5;
    }
}

.textButton {
    padding: 1px 5px;
    color: $black-80;
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    .textButtonIcon {
        margin-right: 0px;
        margin-left: 4px;
    }
    span {
        margin-right: 6px;
    }
    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
    svg {
        margin-right: 6px;
    }
}

.btn-sonar-wrapper {
    z-index: 100;
    position: fixed;
    transform: translateX(-22px);
}
.btn-sonar {
    border: 0;
    border-radius: 50%;
    display: inline-block;
    outline: none;
    position: relative;
    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        top: 0;
        left: 0;
        animation: sonar-effect 2s ease-in-out .1s infinite;
    }
}
@keyframes sonar-effect {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
        box-shadow: 0 0 0 3px rgb(95, 235, 120), 0 0 5px 3px rgb(95, 235, 120), 0 0 0 3px rgb(95, 235, 120);
    }
    45% {
        opacity: 0;
    }
    50% {
        box-shadow: 0 0 0 3px rgba(95, 235, 120, 0), 0 0 20px 30px rgba(95, 235, 120, 0), 0 0 0 20px rgba(95, 235, 120, 0);
        opacity: 0;
    }
}

.error {
    color: #dc3545;
}

.label2 {
    color: $black-30;
}

/* Type --------------- */

/* Body */
.regular {
    font-weight: 400;
}
.medium {
    font-weight: 500;
}
.semiBold {
    font-weight: 600;
}
.bold {
    font-weight: bold;
}

/* Text M */
.textM {
    font-family: Inter, Helvetica, sans-serif;
    font-style: normal;
    // font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* "paragraph" spacing */
    // margin-bottom: 13px;
}

/* Text S */
.textS {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* "paragraph" spacing */
    // margin-bottom: 12px;
}

/* Text XS */
.textXS {
    font-size: 13px;
    line-height: 21px;
    /* "paragraph" spacing */
    // margin-bottom: 11px;
}

/* Text XXS */
.textXXS {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* "paragraph" spacing */
    // margin-bottom: 10px;
    // .textXXS {
    //     font-size: 12px;
    //     font-weight: 500;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: 1.67;
    // }
}

.textXXXS {
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
}

// ******************************************
// MODALS  **********************************
// ******************************************
.modal {
    padding: 0 !important;
}

.modalBottomStrip {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 10px;
    border-top: solid 1px $black-10;
    width: 100%;
}

// ******************************************
/* Miscellaneous ***************************/
// ******************************************
.divider {
    width: 100%;
    height: 1px;
    border-bottom: solid 1px #E6E6E6; /* Black 10% (Borders) */
    margin-top: 18px;
    margin-bottom: 30px;
}

.secondary {
    /* Black 40% (Inactive or 2ndary) */
    color: #999999;
    /* Body / XXS / Regular */
    font-family: Inter, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
}

.tableHeader {
    color: rgba(0, 0, 0, 0.5);
}

// TABLE

table {
    width: 100%;
}
th, td {
    padding: 5px 10px;
    border-bottom: 1px $black-10 solid;
}

.userPointer {
    // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
    -webkit-filter: drop-shadow( 0px 2px 3px rgba(0, 0, 0, .5));
    filter: drop-shadow( 0px 2px 3px rgba(0, 0, 0, .5));
}
mark {
    padding: 0;
    // color: $selected-100;
    background-color: white ;
    font-weight: 700;
}


// TOOLTIPS
.tooltip {
    [class$="inner"] {
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        background: rgba(0, 0, 0, .8);
        color: $black-5;
        font-size: 13px;
        box-shadow: $shadow-small-objects, inset 0 0 0 .5px rgba(255,255,255,0.12);
        padding: 3px 10px;
        border-radius: 4px;
    }
    [class$="arrow"] {
    }
}
.tooltipTippy {
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(30px);
    color: $black-5;
    font-size: 13px;
    padding: 3px 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    kbd {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 16px;
        height: 16px;
        padding: 0px 3px;
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        text-align: center;
        letter-spacing: -0.01em;
        border-radius: 2px;
        background: rgba(255,255,255,0.2);
        color: rgba(255,255,255,0.8);
        pointer-events: none;
        margin-right: 4px;
    }
}
.tooltipTippyCol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    gap: 2px;
}
.tooltipSubtext {
    color: $black-30;
    font-size: 12px;
    font-size: 13px;
}
.tooltipLabel {
    color: $black-30;
    font-size: 12px;
    font-size: 13px;
}
.tippy-box {
    background: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: $elevation--med !important;
    border-radius: 4px;
}

kbd.experimental {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    height: 20px;
    // padding: 0px 3px;
    font-weight: 500;
    font-size: 16px;
    line-height: 13px;
    text-align: center;
    letter-spacing: -0.01em;
    border-radius: 4px;
    background: $black-10;
    pointer-events: none;
}

// Emoji picker
aside.emoji-picker-react {
    padding: 5px !important;
}

// Dialogs
.modalDialogHeader {
    padding-bottom: 20px;
    margin: -20px;
    padding: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid $black-10;
    margin-bottom: 20px;
}

.verticalStack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > *{
        margin-bottom: 10px;
    }
}

.appRow {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    .applicationWithName{
        width: 120px;
        margin-bottom: 30px;
    }
}
.applicationWithName {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.appIcon {
    width: 50px;
    height: 50px;
    border: solid 1px $black-10;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pageSection {
    margin-bottom: 60px;
}
.styleguideContainer {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.styleguideHeroSection {
    background: $black-90;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-bottom: 100px;
    .heroContent{
        padding: 20px;
        max-width: 800px;
        img {
            height: 50px;
            margin-bottom: 70px;
        }
        h1 {
            color: $black-40;
            span {
                color: $white;
            }
        }
    }
    h2 {
        text-align: center;
        position: absolute;
        bottom: 30px;
        color: $white;
    }
}

.entityFrameContent {
    flex: 1 1 auto;
    overflow: hidden;
}
.entity-blob {
    // overflow: auto;
    // height: 100%;
}
.entity-page-hlist {
    height: 100%;
}
.entity-page-hlist > .entity-container {
    overflow: auto;
}

.browsed-home > .entity-container {
    background: white;
    border-radius: 8px 8px 0 0;
}

.schemaTag {
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    color: $black-60;
    background-color: $black-20;
    padding: 0 5px;
    border-radius: 4px;
    margin-left: 8px;
}

.favicon {
    width: 16px;
}

.text {
    color: $text;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
