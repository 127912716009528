@import 'styles/variables';

.settingsModalWrapper {
    width: calc(100vw - 100px);
    max-width: 1150px;
    height: calc(100vh - 100px);
    max-height: 715px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: $elevation--high;
    border-radius: 10px;
}
.settingsModal {
    display: grid;
    height: 100%;
    grid-template-rows: 55px auto;
    grid-template-columns: 200px auto;
    grid-template-areas:
        'header header'
        'sidebar content';
    overflow-y: auto;
}

.menuSubheading {
    font-size: 11px;
    line-height: 18px;
    color: $black-40;
    margin-top: 24px;
    margin-bottom: 2px;
}
.settingsHeader {
    z-index: 1;
    position: sticky;
    top: 0;
    background-color: $white;
    grid-area: header;
    width: 100%;
    padding: 16px 24px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .settingsHeaderTitle {
        display: flex;
        gap: 8px;
    }

    .settingsHeaderText {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
}

.sidebarNav {
    width: 200px;
    .sidebarNavItem {
        width: 100%;
        height: 24px;
        display: block;
    }
}

.settingsTabContent {
    width: 100%;
    padding: 30px;
    grid-area: content;
}

.sidebar {
    display: table;
    table-layout: fixed;
    grid-area: sidebar;
    padding: 0px 16px 16px 16px;
    height: 100%;
    background: #eaeced;
    flex-grow: 0;
    flex-shrink: 0;
    width: 200px;
    overflow-y: auto;
    border-right: solid 1px $black-10;
}
.settingsBody {
    display: flex;
    height: 100%;
    flex-direction: row;
    .settingsPage {
        flex-grow: 1;
        position: relative;
        z-index: 1;
        height: 100%;
    }
}

.menuSubItemWrapper {
    width: 100%;
    box-sizing: border-box;
    margin-right: -32px;
    border-radius: 4px;
}
.menuSubItem {
    height: 32px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 4px;
    padding: 0;
    color: $black-80 !important;
    min-width: 150px;
    display: flex;
    align-items: center;
    gap: 6px;

    &.active {
        background-color: $black-20 !important;
    }
    &:hover {
        background-color: $black-20;
    }
    .menuDataspaceIcon {
        height: 22px;
        width: 22px;
        border-radius: 3px;
        object-fit: cover;
        box-shadow: $elevation--icon;
    }
    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 32px;
    }
}

.userSubMenu {
    display: block;
    padding: 0;
}
