@import 'styles/variables';
@import 'components/modals/contextMenu.module.scss';

$windowFrameHeight: 37px;

.systemViewPort {
    overflow: hidden;
    height: var(--app-height);
    display: flex;
    flex-direction: column;
}

@keyframes delay-pointer-events {
    0% {
        pointer-events: none;
    }
    99% {
        pointer-events: none;
    }
    100% {
        pointer-events: all;
    }
}

.searchButton {
    margin-right: 8px;
}

.windowFrame {
    // &::before {
    //     content: '•••';
    //     position: absolute;
    //     top: -7.5px;
    //     left: 22px;
    //     font-size: 12px;
    //     color: rgb(255 255 255 / 50%);
    //     transition: all 0.5s ease-in-out;
    // }
    z-index: 100;
    position: relative;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex: 0 0 0px;

    height: 37px;
    padding: 12px 12px 12px 20px;

    // height: 12px;
    border-bottom: 1px solid #ffffff00;
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(70px);
    -webkit-backdrop-filter: blur(70px);

    -webkit-app-region: drag;

    // &:hover, &:focus-within {
    //     height: 37px;
    //     padding: 12px 12px 12px 20px;
    //     .opacityControl {
    //         transition: all 0.5s ease-in-out;
    //         opacity: 1;
    //         & * {
    //             animation: delay-pointer-events 0.5s linear forwards;
    //         }
    //     }

    //     //background: rgba(0, 0, 0, 0.4);

    //     & .applicationControls .darkText {
    //         color: $black-80;
    //     }

    //     & .controlMenuButtonDark *, .quickCreateGlobalDark * {
    //         fill: $black-80;
    //     }

    //     &::before {
    //         color: rgb(255 255 255 / 0%);
    //     }
    // }

    &:hover {
        transition-delay: 0.1s;
    }
    .inviteContainer {
        margin-right: 12px;
    }
    .multiplayer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .quickCreateGlobal *:not(:is(:has(svg[data-3d-icon]), svg[data-3d-icon], svg[data-3d-icon] *)),
    .controlMenuButton *:not(:is(:has(svg[data-3d-icon]), svg[data-3d-icon], svg[data-3d-icon] *)) {
        fill: #fff;
        color: white;
    }

    .quickCreateGlobal {
        button:hover {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    .controlMenuButton {
        margin-left: 10px;
        flex-shrink: 0;
    }

    .windowFrameCurrentEntityControlButton {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        margin: 0;
        max-width: 60%;
        filter: drop-shadow(0px 0px 0.25px rgb(0 0 0 / 0.05)) drop-shadow(0px 1px 1px rgb(0 0 0 / 0.3));

        & > span {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            gap: 4px;
            max-width: 100%;

            & > span:last-child {
                font-size: 13px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
            }
        }
    }

    .invite {
        cursor: pointer;
        color: $selected-100 !important;
        stroke: $selected-100 !important;
        svg {
            color: $selected-100;
        }
    }
}

.applicationControls {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.currentApp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    max-width: 400px;
    border-radius: 2px;
}
.appIcon {
    // margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    height: 16px;
    width: 16px;
    font-size: 15px;
    > img {
        width: 16px;
        height: 16px;
        object-fit: cover;
        border-radius: 2px;
        border: 0.5px solid #e6e6e6;
    }
    > svg {
        rect[stroke] {
            stroke: white;
        }
        rect[fill] {
            fill: white;
        }
        path[fill] {
            fill: white;
        }
        path[stroke] {
            stroke: white;
        }
    }
    svg {
        width: 20px;
        height: 20px;
    }
}
.baseAppIcon {
    > svg {
        rect[stroke] {
            stroke: $black-80;
        }
        rect[fill] {
            fill: $black-80;
        }
        path[fill] {
            fill: $black-80;
        }
        path[stroke] {
            stroke: $black-80;
        }
    }
}
.appName {
    max-width: calc(100vw - 300px);
    margin-left: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 22px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    text-transform: capitalize;
}
.baseAppName {
    font-weight: bold;
    color: $text;
    text-shadow: none;
    font-size: 15px;
}
.url {
    margin-left: 8px;
    color: $black-40;
}

.macElectronFrame {
    margin-left: 70px;
}
.windowsElectronFrame {
    margin-right: 140px;
}

.systemDropdownHidden {
    height: 0;
    opacity: 0;
    position: absolute !important;
}

.hiddenMenu {
    top: -24px !important;
    left: var(--leftOffset) !important;
}
.dropdown {
    display: flex;
    margin-right: 12px;
    button {
        cursor: pointer;
        background: none !important;
        color: black !important;
        font-size: 13px;
        border: none !important;
        opacity: 0.75;
        transition: 0.1s ease-out;
        padding: 0;
        &:hover {
            opacity: 1;
        }
        .systemDropdownToggle {
            padding: 4px;
            display: flex;
            align-items: center;
        }
        .systemDropdownToggleWithLabel {
            padding: 4px 12px;
        }
        .systemDropdownToggle:hover {
            background: rgba(255, 255, 255, 0.2);
            border-radius: 4px;
            .homeIcon {
                opacity: 1;
                svg {
                    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));
                }
            }
        }
        .currentApp:hover {
            background: rgba(255, 255, 255, 0.2);
            border-radius: 4px;
        }
        .currentAppDarkHover:hover {
            background: rgba(51, 51, 51, 0.2);
        }
        &[aria-expanded='true'] {
            .currentApp {
                background: rgba(255, 255, 255, 0.4);
                border-radius: 4px;
            }
            .systemDropdownToggle {
                background: rgba(255, 255, 255, 0.4);
                border-radius: 4px;
            }
            .homeIcon {
                opacity: 1;
            }
        }
    }
}
.baseDropdown {
    -webkit-app-region: no-drag;
    button {
        opacity: 1;
        &:hover {
        }
        .currentApp:hover {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }
    }
}
.dropdown-menu {
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.15) !important;
    border: none;
    border-radius: 4px;
    transform: translate(8px, 28px) !important;
    z-index: 1000;
}

.dropdownUser {
    margin: 0 $menu-padding;
    padding: 4px 8px;
    background-color: $black-10;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid $black-25;
    .userAvatar {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        overflow: hidden;
        color: white;
        font-size: 10px;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        .avatarImg {
            pointer-events: none;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .userInfo {
        font-size: 13px;
        line-height: 16px;
        width: calc(100% - 30px);
        div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .userName {
            font-weight: 500;
        }
        .userEmail {
            color: $black-50;
            font-size: 11px;
            line-height: 14px;
        }
    }
}

.currentAppHidden {
    height: 0;
    opacity: 0;
    position: absolute !important;
}

.dropdownSectionLabel {
    font-size: 13px;
    color: $black-50;
    margin-left: 8px;
}

.dropdownSectionDivider {
    border-top: 1px solid $black-10;
    margin: $menu-padding;
}

.dropdownItem {
    font-size: 13px;
    height: 30px;
}

.homeIcon {
    width: 16px;
    height: 16px;
    opacity: 0.75;
    transition: 0.1s ease-out;
    svg {
        display: block;
        margin: 0 auto;
        height: 100%;
        width: 100%;
    }
}
.homeIcon:hover {
    cursor: pointer;
    opacity: 1;
    svg {
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));
        // box-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.3), 0px 0.5px 0.75px 0.25px rgba(0, 0, 0, 0.21);
    }
}

.systemMenuLabel {
    margin-left: 8px;
    color: #fff;
    line-height: 22px;
}

.bedrockHelpMenu {
    position: absolute;
    bottom: 24px;
    right: 16px;
    button {
        background: none !important;
        border: none !important;
    }
}
.bedrockHelpButton {
    user-select: none;
    transition: transform 200ms ease 0s, background 200ms ease;
    cursor: pointer;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    font-size: 20px;
    box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
    z-index: 101;
    transform: translateX(0px) translateZ(0px);
    .questionMark {
        font-size: 28px;
        color: $black-25;
        text-shadow: 1px 1px 2px $black-20-t;
    }

    &:hover {
        background: $black-25;
        .questionMark {
            color: $white;
        }
    }
}
.chatWithAvatar {
    border-radius: 3px;
    box-shadow: $shadow-dragging;
    border: solid 1px #ffffff;
}

.blobPropertyMenu {
    background: none;
    height: 22px;
    width: 22px;
    border: none;
    padding: 0;
    display: flex;
    &:hover {
        background: $black-10;
    }
    &[aria-expanded='true'] {
        // style overrides - bootstrap toggle button
        background-color: $black-10 !important;
        border: none !important;
    }
}

.header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    z-index: 9;
    background-color: black;
    box-shadow: inset 0 -1px lightgrey;
    height: 40px;
    box-sizing: border-box;
}

.header > h1 {
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    color: lightgray;
    margin: 0px;
    line-height: 0.4em;
    font-size: 20px;
}

.header a img {
    height: 25px;
    margin-right: 10px;
}

.title {
    display: block;
    line-height: 25px;
    float: left;
    font-size: 30px;
    font-weight: normal;
    margin-block-end: 0.3em;
}

.title a {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.logo {
    line-height: 25px;
    float: left;
}

.mainWrapper {
    padding: 0 12px 12px;
    height: 100%;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(70px);
    -webkit-backdrop-filter: blur(70px);
}

.mainWrapperBase {
    height: 100%;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}

.mainWrapperElectron {
    background: transparent;
    backdrop-filter: blur(0px);
}

.mainWrapperTauri {
    padding: 0;
}

.main {
    flex: 0 1 auto;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.baseMain {
    composes: main;
    border-radius: unset;
}

.loaderWrapper {
    composes: main;
    background-color: #fff;
}

.pageContent {
    height: 100%;
    overflow-y: auto;
    width: 100%;
}

.finderContent {
    composes: pageContent;
    width: calc(100% - #{$sidebarFullWidth}) !important;
    margin-left: $sidebarFullWidth;
}

.finderContentExpanded {
    composes: pageContent;
    width: calc(100% - #{$sidebarCollapsedWidth}) !important;
    margin-left: $sidebarCollapsedWidth;
}

.objectsSidebarWrapper {
    width: 250px;
    height: 100%;
    float: left;
}

.objectsPageContent {
    height: 100%;
    overflow-y: auto;
    div[data-eid] {
        border-top-right-radius: 0;
    }
}

.objectsPageHListWrapper {
    height: 100%;
    overflow: hidden;
    background: $white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.textFieldInput {
    text-overflow: ellipsis;
    width: 100%;
    &,
    & input {
        overflow: hidden;
        padding: 0;
        padding: 0;
        border-radius: 4px;
        background-color: $gray-secondary;
        font-size: 13px;
        border: none;
        line-height: 23px;
        outline: none;
        color: $text;
        display: block;
        cursor: text;
        height: 28px;
        white-space: nowrap;
        max-width: 400px;
    }
    & input {
        padding: 2px 8px;
        box-shadow: inset 0 0 0 1.5px $selected-100 !important;
        width: 100%;
        margin: 0;
    }
    & input:focus {
        // line-height: 26px;
        padding-top: 0;
    }
}

// Used in WindowFrame/AppDropdown to edit icon and title
// because Context Menus are near $black-3, we are not using
// styleguide input default bg-color $black-3, opting for $black-5
// and including light 0.5px border

.textFieldInputWrapper {
    padding: 5px 8px;
    display: flex;
    align-items: center;
    & div.textFieldInput {
        padding: 2px 8px;
        // margin: 0;
        background-color: $black-5;
        box-shadow: inset 0 0 0 0.5px $black-10;
        // border: solid .5px $black-10;
    }

    & div.textFieldInput:hover {
        background-color: $black-10;
    }
}

.iconWrapper {
    cursor: pointer;
    margin-right: 5px;
    display: flex;
    position: relative;
    background-color: $black-5;
    box-shadow: inset 0 0 0 0.5px $black-10;
    padding: 5px;
    border-radius: 4px;
    &:hover {
        background-color: $black-10;
    }
    & > span {
        line-height: 0;
    }
    & > span > span {
        line-height: 0;
    }
}

.bottomBarIcon {
    cursor: pointer;
    svg {
        height: 18px;
        width: 18px;
        transform: translateY(-1px);
    }
}

.hlistShowButtonInverted {
    transform: rotate(180deg);
}

.showHlistZone {
    z-index: 10;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    width: 38px;

    .showHlistButton {
        z-index: 10;
        position: absolute;
        top: 47%;
        left: 6px;
        visibility: hidden;
        transition: visibility 0.1s ease-in;
        transition-delay: 0.5s;
    }

    &:hover {
        .showHlistButton {
            visibility: visible;
        }
    }
}
