@import 'styles/variables';

.base {
    border-radius: 4px;
    grid-template-columns: auto auto;
    column-gap: 6px;
    grid-gap: 6px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        pointer-events: none;
    }
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.text {
    min-height: 22px;
    .center {
        text-align: center;
    }
    .end {
        text-align: end;
    }
    .start {
        text-align: start;
    }
}

.primary {
    @extend .base;
    background: $primary-button-bg;
    box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.05), inset 0px -0.5px 0px rgba(0, 0, 0, 0.05);

    .text {
        color: $white;
    }
    svg * {
        fill: white;
    }
    &:hover {
        background: $selected-100;
    }
    &:active {
        background-color: $selected-110;
    }
    &.active {
        background-color: $selected-110;
    }
}

.secondary {
    @extend .base;
    background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%);
    box-shadow: inset 0px 0px 0px 0.5px $black-20, inset 0px -0.5px 0px rgba(0, 0, 0, 0.05);

    .text {
        color: $text;
    }
    &:hover {
        background: $black-5;
    }
    &:active {
        background-color: $black-10;
    }
    &.active {
        background-color: $black-10;
    }
}

.secondaryBlue {
    @extend .base;
    // background: linear-gradient(180deg, #DFF5FF 0%, #BCE7FF 100%);
    background: linear-gradient(180deg, #E3F0FF 0%, #BEDCFF 100%);
    box-shadow: inset 0px 0px 0px 0.5px #8BC0FF;

    .text {
        color: $selected-100;
    }
    &:hover {
        background: #BEDCFF;
    }
    &:active {
        background-color: #BCE7FF;
    }
    &.active {
        background-color: #BCE7FF;
    }
}

.tertiary {
    @extend .base;
    background: none;
    box-shadow: inset 0px 0px 0px .5px $black-20;
    
    .text {
        color: $black-50;
    }
    svg {
        path{
            fill: $black-50;
        }
    }
    &:hover {
        background: $black-10;
    }
    &:active {
        background-color: $black-20;
    }
    &.active {
        background-color: $black-20;
    }
}

.plain {
    @extend .base;
    background: none;
    
    .text {
        color: $text;
    }
    &:hover {
        background: $black-10-t;
    }
    &:active {
        background-color: $black-20-t;
    }
    &.active {
        background-color: $black-20-t;
    }
}

.text {
    font-style: normal;
    font-size: inherit;
}

.s {
    padding: 2px 12px;
    &.iconOnly {
        padding: 2px;
        // height: 100%;
        aspect-ratio: 1 / 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .text {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }
}

.m {
    padding: 4px 18px;
    &.iconOnly {
        padding: 4px;
        // height: 100%;
        aspect-ratio: 1 / 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .text {
        font-weight: 600;
        font-size: 17px;
        line-height: 26px;
    }
}

.l {
    padding: 6px 22px;
    &.iconOnly {
        padding: 6px;
    }
    .text {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
    }
}
