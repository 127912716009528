@import 'styles/variables';

.dataspaceControlContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    margin: -30px;
    margin-bottom: 30px;
    padding: 30px;
    border-bottom: 1px solid $black-10-t;
}

.dropdownContainer {
    display: flex;
    gap: 4px;
    align-items: center;
    .dropdownLabel {
        margin-bottom: 0px !important;
    }
}

.generalSettingsContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.avatarNameContainer {
    display: flex;
    gap: 10px;
    // justify-content: space-between;
    align-items: flex-start;
}