.modalContainer {
    display: grid;
    place-items: center;
    width: calc(100vw - 100px);
    max-width: 1150px;
    height: calc(100vh - 100px);
    max-height: 980px;
    overflow: hidden;
    border-radius: 8px;
    background: linear-gradient(to right, #868f96 0%, #596164 100%);

    svg path, svg circle {
        fill: #fff;
    }
}

.image {
    max-width: 750px;
}


.closeButton {
    z-index: 1;
    position: absolute;
    right: 20px;
    top: 20px;
}

.refreshButton {
    margin-right: 10px;
}

.zoomSign {
    color: #fff;
    font-size: 25px;
    font-weight: 400;
}


.modalControls {
    z-index: 1;
    position: absolute;
    bottom: 30px;
    left: 50px;
    display: flex;
    align-items: center;
}

.zoomRange {
    -webkit-appearance: none;
    height: 5px;
    width: 150px;
    border-radius: 5px;
    outline: none;
    padding: 0;
    margin: 2px 10px 0;

    &::-webkit-slider-thumb {
        appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #2c3e50;
        cursor: pointer;
    }
}

.optionsMenu {
    z-index: 1;
    position: absolute;
    bottom: 33px;
    right: 25px;
}

.draggableContainer {
    cursor: pointer;
}

@media (max-width: 780px) {
    .modalContainer {
        height: 70vh;
        width: 90vw;
    }

    .image {
        max-width: 100%;
    }

    .closeButton {
        right: 10px;
        top: 10px;
    }

    .modalControls {
        bottom: 10px;
        left: 15px;
    }

    .zoomRange {
        width: 100px;
        margin: 2px 5px 0;
    }

    .optionsMenu {
        bottom: 13px;
        right: 15px;
    }
}
