@import 'styles/variables';

.newEntityWindow {
    width: calc(100vw - 100px);
    max-width: 1150px;
    height: calc(100vh - 100px);
    max-height: 715px;
    overflow: hidden;
    border-radius: 8px;
    .browsedContainer {
        position: relative;
        height: 100%;
    }
}