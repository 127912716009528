@import 'styles/variables';

.membersTable {
    th,
    td {
        position: relative;
    }
    th:first-child {
        padding-left: 61px;
    }
    input[type='checkbox'] {
        display: block;
        position: absolute;
        width: 13px;
        height: 13px;
        background-color: white;
        margin-left: -23px;
        appearance: auto;
        -webkit-appearance: auto;
        border: 1px solid black;
        border-radius: 2.5px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
    td input[type='checkbox'] {
        visibility: hidden;
    }
    tr td:first-child,
    th:first-child {
        padding-left: 33px;
    }
}
.membersTableRow {
    overflow-x: hidden;
    &:hover {
        background-color: $black-5;
    }
}
.membersTableRow:hover .membersCheckbox {
    visibility: visible;
}
.inviteUsers {
    position: absolute;
    right: 0;
    top: 34px;
    z-index: 10;
    padding: 20px;
    background: white;
    border-radius: 5px;
    min-width: 350px;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 9%), 0px 8px 16px rgb(0 0 0 / 9%), 0px 16px 32px rgb(0 0 0 / 12%),
        0px 32px 64px rgb(0 0 0 / 15%), 0px 64px 128px rgb(0 0 0 / 18%);
    span {
        font-size: 12px;
        color: $black-50;
        margin-bottom: 4px;
    }
    display: flex;
    flex-direction: column;
    .inviteButton {
        margin-top: 20px;
    }
}

.membersRoleDropdown {
    button {
        padding: 3px 6px;
    }
}
.membersRoleDropdownItems {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 220px;
    .dropdownItemContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        min-height: 28px;
        white-space: normal;
        cursor: pointer;
        font-size: 14px;
        padding: 4px;
        border-radius: 4px;
        &:hover {
            background: $black-10-t;
        }
        &.danger {
            color: $error;
            &:hover {
                background: $error-15;
            }
        }
    }
    .dropdownItemText {
        font-size: 14px;
        line-height: 16px;
        color: $black-80;
    }
    .dropdownItemSubtext {
        font-size: 12px;
        color: $black-40;
        line-height: 16px;
    }
}

.memberSelectionCount {
    font-size: 14px;
}

.membersBulkSection {
    display: flex;
    gap: 5px;
    justify-content: right;
    margin-top: 10px;
}

.dropdownToggler {
    display: flex;
    gap: 4px;
    align-items: center;
    padding-left: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background: $black-10-t;
    }
}

.membersBulkButton {
    margin-top: 5px;
    justify-content: right;
}

.headingBlock {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &:not(:first-child) {
        margin-top: 30px;
    }
}
