@import 'styles/variables';
.dropdown {
    border-radius: 4px !important;
    margin-bottom: 1px;
    button {
        border: none;
        background: none !important;
        color: $text !important;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        gap: 6px;
        // justify-content: space-between;
    }
    &:hover {
        background-color: $black-20;
    }
}
.dropdownMenu {
    padding: 6px;
    border: none;
    box-shadow: $shadow-modal-medium;
    z-index: 10000;
    background-color: white;
    a {
        border-radius: 4px;
        padding: 3px 6px;
    }
    a:hover {
        background-color: $black-20;
    }
}
.dropdownToggle {
    border: none;
    background: none !important;
    color: $text !important;
    width: 100%;
    height: 100%;
    border-radius: 0;
    align-items: center;
    display: flex;
    padding: 8px 20px;
    span {
        margin-left: 5px;
    }
}
