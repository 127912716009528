$clear-secondary: rgba(22, 98, 220, 0.349);
$secondary: #5792FA;
$light-secondary: #A5C5FF;
$dark-secondary: #003188;

$white-t:                   rgba(255,255,255,0);
$white-transparent-50:      rgba(255,255,255,0.5);
$white-transparent-75:      rgba(255,255,255,0.75);
$white-transparent-90:      rgba(255,255,255,0.9);
$white:                     #ffffff;

$gray-secondary:            #f7f7f7; // black 3
$black-1:                   #E2E2E2;
$black-3:                   #F7F7F7; // Input default
$black-5:                   #f2f2f2;
$black-5-t:                 rgba(0,0,0,0.05);;
$black-10:                  #e6e6e6;
$black-10-t:                rgba(0,0,0,0.1);
$black-20:                  #ccc;
$black-20-t:                rgba(0,0,0,0.2);
$black-25:                  #bfbfbf;
$black-30:                  #b3b3b3; // Untitled text
$black-40:                  #999; // Inactive or Secondary text
$black-50:                  #808080;
$black-60:                  #666;
$black-70:                  #4d4d4d;
$black-70-transparent:      rgba(0,0,0,0.70);
$black-75:                  #404040;
$black-80:                  #333;
$text:                      #333; // black 80
$black-90:                  #1a1a1a;
$black-100:                 #000;

$browsed:       #d9f1fe; // select 15
$browsed-t:     #d9f1fe00; // select 15 but transparent (safari workaround for how it renders transparent prop)
$selected-5:    #f2faff;
$selected-10:   #e6f6fe;
$selected-20:   #ccecfd;
$selected-30:   #b3e3fd;
$selected-40:   #99d9fc;
$selected-50:   #80cffb;
$selected-60:   #66c6fa;
$selected-70:   #4dbdf9;
$selected-80:   #33b3f9;
$selected-90:   #1aa9f8;
$selected-100:  #00a0f7;
$selected-110:  #008be1;
$selected-5-t:  rgba(0, 160, 247, 0.05);
$selected-10-t: rgba(0, 160, 247, 0.1);
$selected-15-t: rgba(0, 160, 247, 0.15); // Menu Item Selected

$error:       #ff485c;
$error-t:     rgba(255, 72, 92, 0.25);
$error-15:    rgba(255, 72, 92, 0.15);
$attention:   #feaa15;
$attention-t: rgba(254, 219, 21, 0.5);
$success:     #3ad53b;
$success-t:   rgba(58, 213, 59, 0.5);

$accent-1: #e78ef7;
$accent-2: #ca3aa6;
$accent-3: #a42dff;
$accent-4: #657bff;
$accent-5: #027c85;
$accent-6: #00b0a4;
$accent-7: #00d7bc;
$accent-8: #e2ce19;
$accent-9: #e9a170;
$accent-10:#bc5c3d;
$accents: #e78ef7, #ca3aa6, #a42dff, #657bff, #027c85, #00b0a4, #00d7bc, #e2ce19, #e9a170, #bc5c3d;

$accentsOGMac: #FFB900, #F78200, #E23838, #973999, #5EBD3E, #009CDF;

$chiplet-size: 16px;
$spacing-outer: 10px;
$spacing-inner: 4px;

$spacer: 0 !default;
$spacers: () !default;

$sidebarFullWidth: 250px;
$sidebarCollapsedWidth: 46px;

$shadow-modal-medium: 0px 5px 30px 0px rgba(0,0,0,0.15);
$shadow-modal-large: 0px 7.5px 45px rgba(0, 0, 0, 0.15);
$shadow-small-objects: 0px 1px 3px rgba(0, 0, 0, 0.4);
$shadow-dragging: 0px 2.5px 15px rgba(0, 0, 0, 0.15);
$shadow-dragging-into-place: inset 0px 2.5px 15px rgba(0, 0, 0, 0.15);

$elevation--high: 0px 4px 8px rgba(0, 0, 0, 0.09), 0px 8px 16px rgba(0, 0, 0, 0.09), 0px 16px 32px rgba(0, 0, 0, 0.12), 0px 32px 64px rgba(0, 0, 0, 0.15), 0px 64px 128px rgba(0, 0, 0, 0.18);
$elevation--med: 0px 9px 24px rgba(0, 0, 0, 0.2), 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05); // Menus
$elevation--icon: 0px 0px 0px 0.25px rgba(0, 0, 0, 0.05), 0px 1px 2px 0.25px rgba(0, 0, 0, 0.3);
$elevation--icon-svg: drop-shadow(0px 0px 0.25px rgb(0 0 0 / 0.05)) drop-shadow(0px 1px 1px rgb(0 0 0 / 0.3)); // filter
$avatar-size: 23px;

$outline-input-focus: 0 0 0 1.5pt $selected-100; // box-shadow

// Buttons variables
$primary-button-bg: linear-gradient(180deg, #25BEFF 0%, #00A0F7 100%); // background
$theme-object-app-button: linear-gradient(180deg, #4BA0F9 0%, #006DDD 100%); // background
$theme-object-app-button-hover: #006DDD; // background-color
$theme-notes-app-button: linear-gradient(180deg, #f0cc00 0%, #f9b500 100%); // background
// background: linear-gradient(180deg, #ffde25 0%, #f7ce00 100%);
$theme-notes-app-button-hover:#f9b500; // background-color

$hlistItemHeight: 32px;
$hlistItemPadding: 8px;
$hlistItemEdgeDropzoneHeight: 14px;

$hlistLargeItemHeight: 70px;
$hlistMediumItemHeight: 50px;
$hlistLargeItemHorizontalMargin: 11px;
$hlistLargeItemVerticalMargin: 6px;

$base-header-height: 52px;
$base-footer-height: 28px;
