@import 'styles/variables';
@import 'styles/mixins';

.errorContainer {
    border: 1px solid $error;
    border-radius: 4px;
    padding: 1rem;
    width: 350px;
    background-color: $error-t;
}

.bedrockLogo {
    position: fixed;
    bottom: 0;
    margin-bottom: 30px;
    &:hover {
        cursor: pointer;
    }
}
.fullWidthButton {
    width: 100%;
    display: flex;
    justify-content: center;
}
.logInButtonLoginPage {
    width: 100%;
}
.googleButton {
    padding: 6px 12px;
    width: 100%;
    background: #FFFFFF;
    color: $black-50;
    font-size: 16px;
    box-shadow: inset 0px 0px 0px 0.5px $black-20, inset 0px -0.5px 0px rgba(0, 0, 0, 0.05);
    span {
        color: $black-50 !important;
        padding-left: 12px;
    }
    &:hover, &:active, &:focus {
        background: #FFFFFF;
        box-shadow: inset 0px 0px 0px 0.5px $black-20, inset 0px -0.5px 0px rgba(0, 0, 0, 0.05) !important;
    }
}

.emailContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}

.headerStyle {
    text-align: center;
}

.link {
    cursor: pointer;
    color: $selected-100;
}

.loginButton {
    span{
        font-size: 16px !important;
    }
}

.buttonMobile {
    width: 100%;
}

.notFoundText {
    white-space: normal;
    text-align: center;
}

.orGoggle {
    opacity: 1;
}
.inactive {
    opacity: 0.4;
    &:hover {
        opacity: 1;
    }
}

.or {
    text-align: center;
}
.signUpContainer {
    text-align: center;
    margin-top: 32px;
}

.smallTextHeader {
    text-align: center;
    margin-bottom: 25px;
}

.smallTextRow {
    width: 100%;
    padding: 10px;
    text-align: center;

    @include mobile {
        padding: 0px;
    }
}

.earlyAccessButton {
    color: white;
    height: 34px;
    padding: 4px 12px;
    border-radius: 4px;

    > span {
        line-height: 26px;
        font-size: 16px !important;
        font-weight: 600 !important;
    }
}

.earlyAccessButton:hover {
    background-color: #1A9AE0;
}

.signupHeader {
    width: 100%;
    height: 60px;
    color: black;
    padding: 0 5%;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
}

.signupHeaderExpanded {
    height: 100vh;
    transition: height 0.3s ease-in;
    overflow: hidden;
}

.headerBlur {
    background: rgba(236, 241, 247, 0.5);
    backdrop-filter: blur(60px);
    -webkit-backdrop-filter: blur(60px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    pointer-events: none;
}

.headerContent {
    max-width: 1026px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
}

.signupContentExpanded {
    height: 60px;
}

.headerLeft {
    display: flex;
    align-items: center;
    gap: 24px;
}

.headerLogo {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-right: 24px;

    svg {
        border-radius: 50%;
        box-shadow: 0 0 0 0.25px rgba(0, 0, 0, 0.05), 0 1px 2px 0.25px rgba(0, 0, 0, 0.3);
    }

    span {
        font-weight: 700;
        color: $black-75;
    }
}

.selectOption {
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
        width: 20px;
        height: 20px;
        box-shadow: 0px 0px 0px 0.25px rgba(0, 0, 0, 0.05), 0px 1px 2px 0.25px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
    }

    img {
        width: 20px;
        height: 20px;
    }
}

.selectOptionDisabled {
    opacity: 0.5;
}

.optionDescription {
    font-weight: 400;
    color: $black-30;
}

.optionChip {
    padding: 1px 6px 2px;
    background: #4BBCF6;
    box-shadow: 0 0 0 0.25px rgba(0, 0, 0, 0.05), 0 1px 2px 0.25px rgba(0, 0, 0, 0.3);
    border-radius: 9px;
    font-size: 11px;
    line-height: 1.2;
    font-weight: 600;
    color: #fff;
}

.developerIcon {
    height: 100%;
    display: flex;
    align-items: center;
    svg {
        border-radius: 50%;
        box-shadow: none;
    }
}

.headerLink {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: $black-75;
    cursor: pointer;

    &:hover {
        color: #00A0F7;
    }
}

.headerLinkActive {
    color: #00A0F7;
}

.headerRight {
    height: 46px;
    margin-left: auto;
    padding: 6px;
    background: $black-10;
    border-radius: 7px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.headerHamburgerMenu {
    display: none;
    margin-left: auto;
    cursor: pointer;
}

.mobileMenu {
    margin: 20vh auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    gap: 24px;
}

.softBlackBG {
    background-color: #161617;
    color: #F4F5F5;
}

.blackBG {
    background-color: black;
    color: white;
}

.whiteBG {
    background-color: #fff;
    color: black;
}

.grayBG {
    background-color: #dcddde;
    color: black;
}

.pageTitles {
    font-size: 114px;
    font-weight: 600;
    line-height: 1.1;
}

.brand {
    height: 26.1px;
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 0 5%;
}

.itemGroup {
    display: flex;
    flex-direction: row;
}

.galacticaFadeOut {
    animation-name: fadeOut;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

.galacticaFadeIn {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

.galacticaSlideIn {
    animation-name: galacticaSlideInText;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

.galacticaSlideOut {
    animation-name: galacticaSlideOutText;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fadeSlideIn {
    from { opacity: 0; top: 50px; }
    to { opacity: 1; top: 28px; }
}

@keyframes galacticaSlideInText {
    from { top: 0; }
    to { top: -280px; }
}

@keyframes galacticaSlideOutText {
    from { top: -280px; }
    to { top: 0; }
}

.section1Top {
    max-width: 461px;
    margin-bottom: 40px;
}

.section1TopIcons {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 50px;
    line-height: 62px;
    margin-bottom: 18px;
}

.section1TopDescription {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 36px;
    color: $black-40;
}

.section1FinderText {
    font-weight: 700;
    font-size: 50px;
    line-height: 62px;
    color: $black-100;
    margin-top: 4px;
}

.bedrockIcon {
    margin-right: 15px;

    svg {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-shadow: 0 0 0 0.681818px rgba(0, 0, 0, 0.05), 0 2.72727px 5.45455px 0.681818px rgba(0, 0, 0, 0.3);
    }
}

.finderIcon {
    margin: 0 20px;

    svg {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        box-shadow: 0 0 0 0.681818px rgba(0, 0, 0, 0.05), 0 2.72727px 5.45455px 0.681818px rgba(0, 0, 0, 0.3);
    }
}

.iconSeparator {
    font-weight: 200;
    font-size: 64px;
    color: #B3B3B3;
}

.section1ImageWrapper {
    position: relative;
    width: 100%;

    > div {
        position: relative !important;
        border: 10px solid rgba(0, 0, 0, 0.1);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 19px;
    }

    .section1Image {
        width: 100% !important;
        position: relative !important;
        height: unset !important;
        border-radius: 10px;
    }
}

.airtable {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.section {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif !important;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 80px 10%;
}

.section1 {
    background-color: #F5F7F9;
    height: 100vh;
    padding: 100px 5%;
}

.section1Content {
    max-width: 1026px;
    margin: 0 auto;
}

.section2 {
    composes: whiteBG;
    background: linear-gradient(180deg, #C7C7C7 0%, rgba(139, 139, 139, 0.328125) 60.02%, rgba(122, 122, 122, 0) 98.5%), linear-gradient(180deg, rgba(255, 255, 255, 0) 75.62%, #FFFFFF 100%), #F5F7F9;
    height: 4300px;
    justify-content: flex-start;
    padding: 300px 5% 382px;
    position: relative;
    width: 100%;
}

.section2sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.section2Title {
    composes: pageTitles;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 34.4%, #000000 100%), #555555;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 160px;
}

.section2SubTitle {
    font-weight: bold;
    font-size: 62px;
    max-width: 1040px;
    line-height: 110%;
    text-align: center;
    margin-bottom: 100px;
}



.section2BottomTitle {
    composes: pageTitles;
    text-align: center;

    margin-bottom: 380px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 30.21%, rgba(255, 255, 255, 0.273973) 50%, rgba(255, 255, 255, 0.454794) 67.19%, rgba(255, 255, 255, 0.641096) 84.9%, rgba(255, 255, 255, 0.696) 100%), #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.section3BaseSpan {
    white-space:nowrap;
}

.section3FinderIcon {
    margin: 0px 16px 0px 32px;

    svg {
        width: 96px;
        height: 96px;
        transform: translateY(-8px);
        box-shadow: 0 0 0 1.2px rgba(0, 0, 0, 0.05), 0 4.8px 9.6px 1.2px rgba(0, 0, 0, 0.3);
        border-radius: 15px;
    }
}

.section3Content {
    max-width: 1170px;
    width: 100%;
    color: $black-50;
    composes: pageTitles;
    text-align: center;
}

.section3BlackText {
    color: $black-100;
}

.section4 {
    padding: 115px 0;
    overflow: hidden;
}

.section4Title {
    margin: 48px auto;
    max-width: 847px;
    font-size: 64px;
    line-height: 1.1;
    text-align: center;
    font-weight: 600;
}

.section4LineThrough {
    position: relative;
    color: $black-30;

    &::after {
        content: '';
        position: absolute;
        left: -1%;
        top: 30px;
        width: 102%;
        height: 4px;
        background: $black-30;
        transform: rotate(7deg);
    }
}

.section4Images {
    display: flex;
    flex-direction: column;
}

.section4FinderImg {
    margin: 0 auto;
    max-width: 1012px;
    max-height: 660px;
    width: 53%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.06), 0 47.0817px 58.1598px rgba(23, 26, 33, 0.13), 0 19.2305px 24.2978px rgba(23, 26, 33, 0.0934511), 0 10.7486px 12.9907px rgba(23, 26, 33, 0.0774939), 0 6.14403px 7.2825px rgba(23, 26, 33, 0.065), 0 3.26502px 3.86768px rgba(23, 26, 33, 0.0525061), 0 1.33904px 1.60943px rgba(23, 26, 33, 0.0365489);
    border-radius: 16px;
}

.section5 {
    padding: 170px 5%;
    align-items: flex-start;
}

.section5Title {
    composes: pageTitles;
    max-width: 1228px;
    color: $black-100;
    margin: 0 auto 98px;
    text-align: center;
}

.section5Cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.section5Card {
    display: flex;
    padding: 20px;
    background: $gray-secondary;
    border-radius: 20px;
    max-width: 835px;
    width: 43vw;
}

.section5CardText {
    padding: 40px;
    color: $black-100;
}

.section5CardTitle {
    font-weight: 600;
    font-size: 1.455vw;
    line-height: 1.25;
}

.section5CardDescription {
    font-weight: 500;
    font-size: 1.355vw;
    line-height: 1.35;
    color: #585a5c;
}

.section5CardImage {
    img {
        width: 19vw;
        max-width: 378px;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
}

.section6 {
    padding: 165px 5% 250px;
}

.section6Title {
    font-weight: 600;
    font-size: 64px;
    line-height: 1.1;
    color: #2c2d2e;
    margin-bottom: 6px;
}

.section6Subtitle {
    font-weight: 500;
    font-size: 26px;
    line-height: 1.35;
    text-align: center;
    color: #585A5C;
    max-width: 400px;
    margin-bottom: 50px;
}

.section6Cards {
    display: flex;
    gap: 3vw;
    margin-bottom: 50px;
}

.section6Card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section6CardTop {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    width: 12vw;
    box-shadow: 0 0 8.36364px rgba(0, 0, 0, 0.06), 0 0.836364px 25.0909px rgba(0, 0, 0, 0.08), 0 9.84436px 12.1607px rgba(23, 26, 33, 0.13), 0 4.02092px 5.08044px rgba(23, 26, 33, 0.0934511), 0 2.24743px 2.71625px rgba(23, 26, 33, 0.0774939), 0 1.28466px 1.52271px rgba(23, 26, 33, 0.065), 0 0.682685px 0.808697px rgba(23, 26, 33, 0.0525061), 0 0.27998px 0.336517px rgba(23, 26, 33, 0.0365489);
}

.section6CardPersonImg {
    border-radius: 50%;
}

.section6CardText {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    color: $black-50;
}

.section6FinderImg {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.06), 0 4px 120px rgba(0, 0, 0, 0.08), 0 47.0817px 58.1598px rgba(23, 26, 33, 0.13), 0 19.2305px 24.2978px rgba(23, 26, 33, 0.0934511), 0 10.7486px 12.9907px rgba(23, 26, 33, 0.0774939), 0 6.14403px 7.2825px rgba(23, 26, 33, 0.065), 0 3.26502px 3.86768px rgba(23, 26, 33, 0.0525061), 0 1.33904px 1.60943px rgba(23, 26, 33, 0.0365489);
    border-radius: 20px;
    img {
        width: 53vw;
        min-width: 320px;
    }
}

.section7 {
    padding: 120px 5% 175px;
    align-items: flex-start;
}

.section7ContentWrapper {
    max-width: 1120px;
    margin: 0 auto;
}

.section7Title {
    font-weight: 600;
    font-size: 28px;
    line-height: 1.25;
    color: $black-100;
    margin-bottom: 20px;
}

.section7Cards {
    display: flex;
    gap: 26px;
}

.section7Card {
    background-color: $gray-secondary;
    padding: 53px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 551px;
    border-radius: 30px;
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
        box-shadow: 0 0 0 10px #00A0F7;
    }
}

.section7CardTitle {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif !important;
    font-size: 72px;
    line-height: 1.1;
    font-weight: 600;
    color: $black-100;
}

.section7CardText {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif !important;
    font-size: 25px;
    line-height: 1.35;
    font-weight: 500;
    color: $black-100;
}

.section7CardBottom {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.section7CardBottomText {
    font-size: 22px;
    line-height: 135%;
    color: rgba(0, 0, 0, 0.5);
    height: 60px;
}

.section7CardGalacticaButtons {
    display: flex;
    gap: 16px;
}

.section7CardButton {
    height: 46px;
    width: 181px;
    padding: 9px 19px;
    flex-grow: 0;
    flex: none;
    white-space: nowrap;

    > span {
        font-family: Inter, Helvetica, sans-serif !important;
        font-weight: 600 !important;
        font-size: 23px !important;
    }
}

.section7GalacticaCardButton {
    composes: section7CardButton;
    width: 210px;
}

.section8 {
    composes: whiteBG;
}

.section8Title {
    margin-top: 300px;
    composes: pageTitles;
    text-align: center;
}

.section8Arrow {
    font-family: Inter, Helvetica, sans-serif;
    composes: section8Title;
    margin-top: 118px;
    margin-bottom: 84px;
    text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.22), 0px 4px 44px rgba(0, 0, 0, 0.15);
}

.section9 {
    composes: whiteBG;
}

.section9SubTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 1.25;
    color: #999;
    margin-bottom: 10px;
    margin-top: 300px;
    text-align: center;
}

.section9Title {
    composes: pageTitles;
    margin-bottom: 300px;
    text-align: center;
}

.section10 {
    composes: whiteBG;
}

.section10SubTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 1.25;
    color: #999;
    margin-bottom: 10px;
    margin-top: 150px;
}

.section10Title {
    composes: pageTitles;
    margin-bottom: 150px;
    text-align: center;
}

.section11 {
    composes: whiteBG;
    align-items: flex-start;
    padding: 200px 8% 400px;
}

.section11TextWrapper {
    position: relative;
    text-align: center;
    max-width: 1345px;
    width: 80vw;
    margin: 0 auto;
}

.section11SubTitle {
    margin: 0 auto 10px;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.25;
    color: #999;
}

.section11Title {
    composes: pageTitles;
    width: 100%;
    position: absolute;
    top: 28px;
    left: 0;
}

.section11SecondTitle {
    composes: section11Title;
    font-size: 60px;
    opacity: 0;
}

.section11TitleFadedOut {
    animation-name: fadeOut;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

.section11TitleFadedIn {
    composes: section11SecondTitle;
    animation-name: fadeSlideIn;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

.section12 {
    max-width: 1420px;
    padding: 80px 8% 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 5vw;
    align-items: center;
}

.section12FinderImg {
    width: 53vw;
    max-width: 1012px;
    img {
        width: 100%;
        border-radius: 20px;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.06), 0px 4px 120px rgba(0, 0, 0, 0.08), 0px 47.0817px 58.1598px rgba(23, 26, 33, 0.13), 0px 19.2305px 24.2978px rgba(23, 26, 33, 0.0934511), 0px 10.7486px 12.9907px rgba(23, 26, 33, 0.0774939), 0px 6.14403px 7.2825px rgba(23, 26, 33, 0.065), 0px 3.26502px 3.86768px rgba(23, 26, 33, 0.0525061), 0px 1.33904px 1.60943px rgba(23, 26, 33, 0.0365489);
    }
}

.section12List {
    list-style: none;
}

.section12ListItem {
    cursor: pointer;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 20px;
    padding: 10px 20px;
    display: flex;
    gap: 15px;
    width: 305px;
    align-items: center;
    border-radius: 20px;
    transition: background-color 0.3s linear;
}

.section12ListItemChosen {
    composes: section12ListItem;
    background-color: #F5F7F9;
}

.section12ItemCircle {
    width: 41px;
    height: 41px;
    background-color: #dcddde;
    border-radius: 50%;
}

.section13 {
    padding: 0;
    color: #fff;
    height: 2500px;
    justify-content: flex-start;
}

.section13Darken {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #101010;
    opacity: 0;
    z-index: 1;
    pointer-events: none;

    transition: opacity 0.2s linear;
}

.section13Wrapper {
    padding: 100px 5%;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: radial-gradient(39.13% 39.13% at 40.46% -9.29%, rgba(255, 255, 255, 0.37) 0%, rgba(255, 255, 255, 0) 100%), #101010;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    flex-direction: column;
}

.section13Logo {
    margin-bottom: 10px;
    svg {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-shadow: 0px 0px 0px 0.681854px rgba(0, 0, 0, 0.05), 0px 2.72741px 5.45483px 0.681854px rgba(0, 0, 0, 0.3);
    }
}

.section13MainContent {
    text-align: center;
    width: 100%;
    opacity: 0;
}

.section13Title {
    font-weight: 600;
    font-size: 140px;
    line-height: 1.1;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 22.4%), linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), url('/images/landingPage/section13_font-image.png'), #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-clip: text;
    white-space: nowrap;
    margin-bottom: 10px;
}

.section13SubTitle {
    font-weight: 600;
    font-size: 44px;
    line-height: 1.1;
    margin-bottom: 40px;
    background: linear-gradient(180deg, rgba(80, 80, 80, 0) 55.21%, rgba(89, 89, 89, 0.83) 100%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.section13Images {
    display: flex;
    justify-content: center;
    gap: 3px;
    width: 100%;
    margin-bottom: 40px;

    .section13ImageWrapper {
        position: relative;
        width: 20%;
        max-width: 357px;

        div {
            position: relative !important;
        }

        .section13Image {
            position: relative !important;
            height: unset !important;
        }
    }
}

.section13Bottom {
    position: relative;
    font-weight: 600;
    font-size: 76px;
    line-height: 1.05;
    text-align: center;
    background: radial-gradient(116.78% 193.61% at 46.03% -47.25%, rgba(255, 255, 255, 0.828) 20.31%, rgba(255, 255, 255, 0.405) 62.5%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #CCCCCC;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    z-index: 2;
}

.section14 {
    padding: 327px 10%;
    background: linear-gradient(180deg, #101010 0%, rgba(16, 16, 16, 0) 50.01%), #000000;
}

.section14Logo {
    margin-bottom: 70px;
    svg {
        width: 100px;
        height: 100px;

        path {
            fill: #666;
        }
    }
}

.section14SubTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 1.25;
    color: $black-40;
    text-align: center;
}

.section14Title {
    composes: pageTitles;
    text-align: center;
    color: $white;
}

.section15 {
    padding: 250px 350px;
    background-color: #f5f7f9;
    color: $black-100;
}

.section15Title {
    font-weight: 600;
    font-size: 64px;
    line-height: 1.1;
    text-align: center;
    color: #2c2d2e;
    margin-bottom: 105px;
    max-width: 630px;
}

.section15Cards {
    display: flex;
    gap: 20px;
}

.section15CardColumn {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.section15Card {
    min-width: 300px;
    padding: 22px;
    font-family: Inter, Helvetica, sans-serif;
    border: 1px solid #DCDDDE;
    border-radius: 8px;
    max-width: 389px;
}

.cardTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.cardPerson {
    display: flex;
    gap: 7px;
}

.cardNameAndUsername {
    padding: 7px 0;
}

.cardName {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.03em;
    margin-bottom: 3px;
    display: flex;
    gap: 4px;
    align-items: center;
}

.cardUsername {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.03em;
    color: #8E8E8E;
}


.githubIcon {
    svg {
        width: 27px;
        height: 21px;
    }
}

.cardBody {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: -0.03em;
    margin-bottom: 14px;

    .cardBodyImage {
        padding-top: 10px !important;
    }
}

.cardMediumFooter {
    display: flex;
    gap: 13px;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.03em;
    color: #8E8E8E;
}

.cardTwitterFooter {
    display: flex;
    gap: 15px;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.03em;
    color: #8D98A5;
}

.cardGithubFooter {
    display: flex;
    gap: 4px;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.03em;
    color: $black-70;
}

.cardFooterItem {
    display: flex;
    align-items: center;
    gap: 5px;
}

.cardTextMarked {
    background-color: #FEDEFF;
}

.cardFooterText {
    display: flex;
    gap: 4px
}

.cardBodySeparator {
    height: 20px;
}

.cardBlueText {
    color: #2AA3EF;
}

.cardDarkBlueText {
    color: #2E66D2;
}

.cardFooterCountText {
    color: $black-90;
}

.cardGithubFooterCountText {
    padding: 1px 6px;
    background: $black-5;
    border-radius: 10px;
}

.footer {
    padding: 60px 10%;
    background-color: #f5f7f9;
    color: $black-100;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    gap: 8vw;
}

.footerBedrockInfo {
    max-width: 363px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footerLogo {
    margin-bottom: 15px;

    svg {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        box-shadow: 0px 0px 0px 0.568211px rgba(0, 0, 0, 0.05), 0px 2.27285px 4.54569px 0.568211px rgba(0, 0, 0, 0.3);
    }
}

.footerBedrockInfoText {
    font-family: Inter, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 5px;
}

.footerBedrockInfoLink {
    composes: footerBedrockInfoText;
    a {
        color: #00A0F7;
    }
}

.footerBedrockImg {
    margin-bottom: 100px;
}

.footerHelpLinks {
    display: flex;
    gap: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;

    a {
        color: $black-100;
        text-decoration: underline;

        &:hover {
            color: #00A0F7;
        }
    }

    svg {
        width: 17px;
        height: 14px;
        cursor: pointer;

        path {
            fill: $black-40;
        }

        &:hover {
            path {
                fill: #00A0F7;
            }
        }
    }
}

.footerLinks {
    font-family: Inter, Helvetica, sans-serif;
    display: flex;
    gap: 3vw;
}

.footerLinksTitle {
    font-weight: 600;
    font-size: 13px;
    line-height: 1.25;
    margin-bottom: 12px;
}

.footerLinksList {
    list-style: none;
    padding: 0;
    margin-bottom: 36px;

    li {
        font-weight: 500;
        font-size: 13px;
        line-height: 1.25;
        color: #585A5C;
        margin-bottom: 12px;

        a {
            color: #585A5C;

            &:hover {
                color: #00A0F7;
            }
        }
    }
}

.hiringLink {
    display: flex;
    gap: 3px;
    align-items: center;
}

.hiringBrand {
    padding: 1px 6px 2px;
    margin-left: 4px;
    background: #4BBCF6;
    width: 48px;
    height: 16px;
    border-radius: 9px;
    font-family: Inter, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 1.2;
    color: $white;
}

@media (max-width: 480px) {
    .footer {
        align-items: flex-start;
    }

    .footerLinks {
        flex-direction: column;
        gap: 0;
    }
}

@media (max-width: 768px) {
    .headerLeft, .headerRight {
        display: none;
    }
    .headerHamburgerMenu {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pageTitles {
        font-size: 68px;
    }

    .section1Top {
        font-size: 42px;
    }

    .section1Bottom {
        font-size: 18px;
    }
    .section2SubTitle {
        font-size: 38px;
    }

    .section3FinderIcon {
        svg {
            width: 57px;
            height: 57px;
        }
    }

    .section4Title {
        font-size: 38px;
    }

    .section4LineThrough {
        &::after {
            top: 18px;
            height: 3px;
        }
    }

    .section5Card {
        width: 100%
    }

    .section5CardText {
        padding: 0 10px 0 0;
    }

    .section5CardTitle {
        font-size: 20px;
    }

    .section5CardDescription {
        font-size: 18px;
    }

    .section5CardImage {
        img {
            width: 100%;
            min-width: 170px;
        }
    }

    .section6Title {
        font-size: 38px;
    }

    .section6Subtitle {
        font-size: 18px;
    }

    .section6Cards {
        flex-direction: column;
        gap: 20px;
    }

    .section6CardTop {
        width: 100%;
    }

    .section7Title {
        font-size: 20px;
    }

    .section7Card {
        padding: 30px;
        gap: 20px;
        width: 70vw;
        &:hover {
            box-shadow: inset 0 0 0 4px #00A0F7;
        }
    }

    .section7CardTitle {
        font-size: 40px;
    }

    .section7CardText {
        font-size: 16px;
    }

    .section7CardBottom {
        gap: 15px;
    }

    .section7CardBottomText {
        font-size: 15px;
    }

    .section7CardButton {
        height: 30px;
        width: 120px;
        padding: 6px 10px;

        span {
            font-size: 16px !important;
        }
    }

    .section7GalacticaCardButton {
        width: 145px;
    }

    .section11SecondTitle {
        font-size: 36px;
    }

    .section12 {
        flex-direction: column;
        gap: 30px;
    }

    .section12FinderImg {
        order: 2;

        img {
            width: 100%;
        }
    }

    .section12List {
        order: 1;
    }

    .section12ListItem {
        font-size: 18px;
        margin-bottom: 10px;
        padding: 5px 10px;
        width: 200px;
    }

    .section12ItemCircle {
        width: 30px;
        height: 30px;
    }

    .section13Wrapper {
        height: 100vh;
    }

    .section13Title {
        font-size: 68px;
    }

    .section13SubTitle {
        font-size: 30px;
    }

    .section13Bottom {
        font-size: 45px;
    }

    .section13Image {
        width: 70vw;
        img {
            width: 100%;
        }
    }

    .section14Logo {
        margin-bottom: 50px;
        svg {
            width: 70px;
            height: 70px;
        }
    }

    .section14SubTitle {
        font-size: 20px;
    }

    .section15 {
        padding: 250px 10% 100px;
    }

    .section15Title {
        font-size: 38px;
    }

    .section15Card {
        width: 70vw;

        img {
            width: 100%;
        }
    }

    .footerBedrockInfoText {
        font-size: 20px;
    }

    .footerBedrockInfoLink {
        font-size: 20px;
        span {
            font-size: 28px;
        }
    }

    .footerBedrockImg {
        max-width: 80%;
    }

    .footerHelpLinks {
        gap: 10px;
        font-size: 12px;
    }
}

@media (max-width: 1240px) {
    .section7Cards {
        flex-direction: column;
        gap: 20px;
    }

    .section15Cards {
        flex-direction: column;
        align-items: center;
    }

    .footer {
        flex-direction: column;
        gap: 50px;
    }
}

// Chaos animation

.chaosBGWrapper {
    position: relative;
    filter: grayscale(1);

    > div:not(.chaosIconWrapper) {
        position: relative !important;
    }
    .chaosBG {
        width: 100% !important;
        position: relative !important;
        height: unset !important;
        max-width: 80vw !important;
        max-height: 80vh !important;
    }
}

.chaosIconWrapper {
    position: absolute;
    width: 8%;
    overflow: hidden;

    div {
        position: relative !important;
    }

    .chaosIcon {
        position: relative !important;
        height: unset !important;
    }
}

.chaosApp {
    composes: chaosIconWrapper;
    box-shadow: rgb(0 0 0 / 15%) 0px 0
}

.triggered {
    animation-name: pop;
    animation-duration: .3s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;

    -webkit-animation-name: pop;
    -webkit-animation-duration: .3s;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-out;
}

.untriggered {
    animation-name: unpop;
    animation-duration: 0.2s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;

    -webkit-animation-name: unpop;
    -webkit-animation-duration: 0.2s;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-out;
}

@keyframes pop {
    0% { transform: translateY(10px) scale(0); }
    33% { transform: translateY(5px) scale(0); }
    66% { transform: translateY(2px) scale(1.2); }
    100% { transform: translateY(0px) scale(1); }
}
@keyframes unpop {
    from { transform: translateY(0px) scale(1); }
    to { transform: translateY(10px) scale(0); }
}
