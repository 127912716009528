@import 'styles/variables.scss';

.tabsHighlight {
  background: $black-10-t;
  position: absolute;
  left: 0;
  border-radius: 8px;
  height: 35px;
  transition: 0.15s ease;
  transition-property: width, transform, opacity;
}

.tab {
  padding: 6px 12px;
  height: 35px;
  font-size: 14px;
  color: $text;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: color 250ms;
  border-radius: 8px;
  // opacity: 0.3;
  flex: 1;
  text-align: center;

  // &:hover {
  //   color: hsl(0 0% 9%);
  // }
  & > * {
    pointer-events: none;
  }
}

.active {
  opacity: 1;
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.tabsNav {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 2px;
  // box-shadow: 0 0 0 0.5pt #ccc;
  border-radius: 10px;
  padding: 4px;
  background-color: #f5f5f5;
  
  &:hover {
    .tab {
      // opacity: 1;
    }
    .active {
      
    }
  }

}
.tabsNavSmall {
  border-radius: 6px;
  
  .tabsNav {
    padding: 2px;
  }
  .tabsHighlight, .tabsNav {
    border-radius: 6px;
    height: 16px;
  }
  .tab {
    height: 16px;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.5;
  }
  .active {
    opacity: 1;
  }
}
