@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@font-face {
    font-display: swap;
    font-family: neue haas grotesk display pro;
    font-style: normal;
    font-weight: 400;
    src: local('Neue Haas Grotesk Display Pro'),url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayLight.woff) format('woff')
}

@font-face {
    font-display: swap;
    font-family: neue haas grotesk display pro;
    font-style: normal;
    font-weight: 500;
    src: local('Neue Haas Grotesk Display Pro'),url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayRoman.woff) format('woff')
}

@font-face {
    font-display: swap;
    font-family: neue haas grotesk display pro;
    font-style: normal;
    font-weight: 600;
    src: local('Neue Haas Grotesk Display Pro'),url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayMediu.woff) format('woff')
}

@font-face {
    font-display: swap;
    font-family: neue haas grotesk display pro;
    font-style: normal;
    font-weight: 700;
    src: local('Neue Haas Grotesk Display Pro'),url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayBold.woff) format('woff')
}
