@import 'styles/variables';

.formContainer {
    position: absolute;
    right: 0;
    z-index: 10;
    padding: 10px;
    box-shadow: $elevation--high;
    background: white;
    border-radius: 7px;
    top: 0;
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;;
    align-items: flex-end;
}

.formSecondRow {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 8px;
}