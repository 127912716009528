@import 'styles/variables';

.entity {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: $white;
    overflow: hidden;
    position: absolute;
    opacity: 1;
    z-index: 1;
}

.baseEntity {
    composes: entity;
    border-radius: unset;
}

.entity[data-eid="root"] {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
}

.obsolete {
    opacity: 60%;
    flex-direction: row-reverse;
}

.inactive {
    display: none;
}

.queryFilter {
    display: flex;
    flex-direction: column;
}

.container {
    white-space: nowrap;
    position: fixed;
    bottom: 0px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgray;
    z-index: 7;
    background-color:rgba(255,255,255,0.5);
    backdrop-filter: blur(3px);
}

.container:open {
    white-space: normal;
}

.moduleLabelText {
    font-size: 11px;
}

.moduleLabel {

    padding: 5px;
    font-size: 12px;

    > .moduleLabelText {
        font-size: 14px;
        color: gray;
    }

    > .close-icon-button {
        position: absolute;
        top: 5px;
        right: 15px;
    }
}

.stub {
    display: none;
}

.image {
    max-width: 100%;
}

.finder {
    // height: calc(100vh - 72px);
    // overflow: auto;
    // position: relative;
    // :nth-child(even) {
    //   background-color: #f2f2f2;
    // }
    scroll-behavior: smooth;
    // background-color: $black-3;
}

.dataspaceIcon {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    object-fit: cover;
    border: 0.5px solid $black-10;
    margin-bottom: 0;
}

.entityPage {

    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px);
    overflow: auto;

    [class*="entity"] {
        margin: 0 auto;
        max-width: 750px;
    }
    a {
        color: inherit;
        text-decoration: none;
    }

    > div > .entity-item-list {
        width: 200px;
        float: right;
    }

    .dnd-list-item {
        width: 100%;
    }
}

.richText {
    // overflow-y: auto;
    // max-width: 708px;
    // margin: 0 auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.spreadsheet {
    display: block;
}

.promptContainer {
    height: 100%;
    width: 100%;
    min-height: 80px;
    padding: $spacing-outer;
    cursor: default !important;

    .prompt {
        display: flex;
        align-items: center;
        color: $black-40;
        visibility: hidden;
        max-width: 750px;
        margin: 0 auto;

        span {
        margin-top: 2px;
        margin-left: 2px;
        }
    }

    &:hover {
        .prompt {
        visibility: visible;
        }
    }
}

.Image {
    overflow-y: auto;
    background: #000000;
    img {
        max-width: 100%;
    }
}
.PDF {
    height: 100%;
    width: 100%;
    embed {
        height: 100%;
        width: 100%;
    }
}

.hlistContent {
    flex: 1;
    overflow-y: auto;
    padding: 0 8px;
}

.hlistRows {
    position: relative;
    z-index: 1;
}
  
.center {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.children {
    padding-left: 20px;
}

.driveFilesContainer {
    height: 100%;
    overflow-y: auto;
}

.driveFileName {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.driveFileRow {
    cursor: pointer;
    border-left: 3px solid white;
    &:hover {
        background-color: $browsed;
    }
}
.activeDriveFile {
    border-left: 3px solid $selected-100;
    background-color: $browsed;
}


// ----- EntityFrame


.icon {
    margin-right: 10px;
}
  
.homeIcon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    opacity: .5;
    transition: 0.1s ease-out;
    svg {
        display: block;
        margin: 0 auto;
    }
}
.homeIcon:hover {
    cursor: pointer;
    opacity: 1;
    svg {
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));
    }
}

.frame {
    box-sizing: border-box;
    // border-radius: .5em;
    height: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.content {
    flex: 1 1 auto;
    overflow: auto;
    & > div:first-child {
        height: 100%;
    }
}
.frameButton {
    height: 15px;
    width: 15px;
    display: flex;
    position: relative;
}

.hlistContainer {
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s linear;
    height: 100%;
    &:hover {
        background-color: white;
    }
}
.hlistContainerBaseApp {
    padding-top: 8px;
}
.hlistContainerNotBrowsed {
    background-color: white;
}

// flex parent
.topBar {
    width: 100%;
    // color: #777777;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    height: 52px;
    min-height: 32px;
    box-shadow: inset 0 -1px 1px $black-10;
    flex: 0 1 auto;
    font-size: 15px !important;
    &:hover {
        color: $black-90;
    }
}

.topBarAvatarsMenuBtn {
    display: flex;
    gap: 10px;
}

.notesTopBar {
    position: relative;
    box-shadow: none;
    justify-content: flex-end;
    min-height: 28px;
    height: 28px;
}

.baseEntityTopBar {
    max-height: 32px;
    height: 32px;
}

.notesEntityName {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 13px;
    line-height: 21px;
    font-weight: 600;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.baseTopBar {
    composes: topBar;
    height: $base-header-height;
    justify-content: space-between;
    min-height: unset;
    background: $black-3;
    padding-left: 85px;
    -webkit-app-region: drag;
}

.baseTopBarSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.baseTopBarSeparator {
    color: $black-30;
    padding-right: 5px;
}

.pathElementWrapper {
    display: flex;
    align-items: center;
}
.baseTopBarPathItem {
    display: flex;
    align-items: center;

    max-height: 22px;
    gap: 5px;
    padding: 2px 5px;

    border-radius: 3px;
    font-size: 13px;

    cursor: pointer;
    &:hover {
        color: $black-90;
        background: $black-10;
    }

    .name {
        // max symbol count is 15, and ellipsis the rest
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 150px;
    }
}

.dropdownElementWrapper {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    &:hover {
        color: $black-90;
    }
}

.dropdownElementToggle {
    padding: 0px 5px;
    font-size: 13px;
    background: transparent;
    color: $black-90;
    border: none;
    outline: none;
    &:active, &:hover {
        background: $black-20;
        color: $black-100;
    }
    &:focus {
        background: $black-20;
        color: $black-90;
    }
}

.dropdownElementMenu {
    padding: 8px 8px;
    border-radius: 4px;
    box-shadow: $elevation--med;
    border: none;
    background: $black-3;
    border: none;
    border-radius: 4px;
}

.dropdownElementItem {
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 2px 5px;
    // margin: 0 8px;
    gap: 5px;
    border-radius: 3px;
    &:hover {
        color: $black-90;
        background: $black-10;
    }
}

.baseTopBarInput {
    width: 15px;
    height: 16px;
    border-bottom: 2px solid $selected-100;
    animation: blink 1s infinite;
    cursor: pointer;
    z-index: 10000000;
    // &.focused {
    //     @keyframes blink {
    //         0%, 40%, 60%, 100% {
    //             border-bottom-width: $selected-100;
    //         }
    //         50% {
    //             border-color: transparent;
    //         }
    //     }
    // }
}

// flex child
.title {
    flex: 1;
    min-width: 0;
    display: flex;
    align-items: center;
    .textFieldInput {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        font-size: 14px;
        font-weight: 700;
    }
    form.topBarRenameField {
        textarea {
            font-weight: 700;
            font-size: 14px;
            color: $text;
            width: 100%;
            resize: none;
            height: auto;
            min-height: 22px;
            border: none;
            outline: none;
            line-height: 1.1rem;
            overflow: hidden;
            padding: 2.5px 2px 2px 2px;
            margin: 0;
            border-radius: 2px;
            display: block;
        }
    }
}
.selectedItemsLabel {
    color: $selected-100;
    margin: 0px 10px;
    font-size: 12px;
}

// flex child
.btnGroup {
    display: flex;
    align-items: center;
    height: 28px;
    gap: 6px;
    button {
        height: 22px;
    }
}

.clickableTitle {
    width: 100%;
    cursor: pointer;
}

.noStyleInput {
    padding: 0;
    margin: 0;
    margin-block-end: 0;
    margin-block-start: 0;
    // max-width: 300px;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    form {
        input {
            background: none !important;
            padding: 0;
            border: none;
            outline: none !important;
            box-shadow: none !important;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.53;
            width: 100%;
        }
  }

    div {
        width: 100%;
    }
}

.typeTag {
    background-color: #999;
    color: #fff;
    font-size: 14px;
    padding: 3px 8px;
    margin-left: 15px;
    border-radius: 2px;
}

.iconWrapper {
    cursor: pointer;
    margin-right: 8px;
    display: flex;
    position: relative;
    & > span {
        line-height: 0;
    }
    & > span > span {
        line-height: 0;
    }
    &:hover {
        .emojiPickerBg {
        visibility: visible;
        }
    }
    .emojiPickerBg {
        background-color: $black-5;
        width: 130%;
        height: 140%;
        position: absolute;
        top: -20%;
        left: -15%;
        border-radius: 5px;
        visibility: hidden;
        pointer-events: none;
        z-index: -1;
    }
}

.deletedEntitiesControls {
    display: flex;
    align-items: center;
}


.sharedIcon {
    color: $selected-100;
}

.emojiPickerZIndexWrapper{
    position: fixed;
    top:32px;
    z-index: 1000;
}

.emojiPickerWrapper{
    font-family: Inter, Helvetica, sans-serif !important;
    font-style: normal;
    font-size: 13px  !important;
    position: absolute !important;
    z-index: 2000;
    // padding: 6px 6px 0px 6px;
    border-radius: 5px;
    border: none;
    background: #fff !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0px 5px 30px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.emojiPickerHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $black-10;
}

.emojiPickerTabs {
    display: flex;
}

.emojiPickerTab {
    padding: 5px 10px;
    border-bottom: 2px solid $white-t;
    cursor: pointer;
}
.emojiPickerTabActive {
    @extend .emojiPickerTab;
    border-bottom: 2px solid $selected-100;
}
.emojiPickerTabSoon {
    @extend .emojiPickerTab;
    opacity: 0.5;
    pointer-events: none;
}
.emojiRemoveButton {
    margin-right: 5px;
}

.iconContainer {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    :is(svg[data-3d-icon]) {
        filter: drop-shadow(0px 0px 0.25px rgb(0 0 0 / 0.05)) drop-shadow(0px 1px 1px rgb(0 0 0 / 0.3));
    }
}

.defaultCursor {
    cursor: default;
}

.keyboardButton {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin-right: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 4px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
}

.appImporterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 8px;
}
