@import 'styles/variables';

.inviteLinkField {
    white-space: nowrap;
    > * {
        vertical-align: middle;
    }
}

.input {
    display: inline-block;
    border: 1px solid #ccc;
    width: calc(100% - 120px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 14px;
    height: 30px;
    border-right: none;
    &:hover,
    &:active,
    &:focus {
        border: 1px solid #ccc;
        border-right: none;
    }
}

.button {
    display: inline-block;
    width: 120px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0;
    font-size: 14px;
    padding: 2px;
    height: 30px;
    background: $primary-button-bg;
    border: none;
}
