@import 'styles/variables';

.tabsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(232,234,237);
  height: 52px;
  min-height: 52px;
  width: 100%;
  overflow-x: auto;
  padding: 0 8px;
}

.tab {
  height: 36px;
  padding: 1px 12.5px;
  background-color: rgb(230,230,2);
  box-sizing: border-box;
  transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out;
  cursor: default;

  .summaryInnerContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      width: inherit;
      white-space: nowrap;
      min-width: 100%;
  }
  list-style: none;
  &::-webkit-details-marker {
      display: none;
  }
}

.browsedTab {
  background-color: var(--bg-color, rgb(255,255,255));
  --bg-color: rgb(255,255,255);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.notBrowsedTab {
  background-color: var(--bg-color, rgb(232,234,237));
  --bg-color: rgb(232,234,237);

  &:hover {
    background-color: var(--bg-color, rgb(248,249,250));
    --bg-color: rgb(248,249,250);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.tabName {
  font-weight: 500;
  font-size: 15px;
  overflow: hidden;
}

.notPersisted {
  font-style: italic;
}

.baseBackground {
  background-color: #fff;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 220px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 8px;
}

.dragging {
  opacity: .25;
}

.pointerEventsNone {
  pointer-events: none !important;
}

.compactSymbol {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;

    .entityName {
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        .entityNameTag {
            // width: 100%;
            overflow: hidden;
        }
    }
    .entityNameFlat {
        overflow: hidden;
        height: 100%;
        display: flex;
        align-items: center;
        div {
            width: 100%;
            overflow: hidden;
            margin-right: 20px;
        }
    }
}

.chiplet {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: text-top;
  // margin-left: 4px;
  position: relative;

}

.chipletBase {
  width: $chiplet-size;
  height: $chiplet-size;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // margin-right: $spacing-inner;
  border-radius: 4px;
  margin-right: 8px;
  filter: drop-shadow(0px 0px 0.25px rgb(0 0 0 / 0.05)) drop-shadow(0px 1px 1px rgb(0 0 0 / 0.3));
}

.chipletSection {
  @extend .chipletBase;

  svg {
    margin-top: -1px;
    display: inline-block;
  }

  &[data-type="arrow"] {
    &:hover {
      background: $black-10-t;
    }
  }
  .chipletToggle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 175ms cubic-bezier(0.25, 0.1, 0.25, 1) 0ms;
  }
  .chipletToggleOpen {
    transform: rotate(90deg);
  }
}

.linkchip {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 4px;
  &:hover {
    background-color: $black-10-t;
  }
  svg {
    display: block;
  }
}

.closeButton {
  display: none;
  position: static;
  top: 0;
  right: 0;
}
.tab:hover .closeButton {
  display: flex;
}

.dropHover {
  background-color: $selected-30;
}

.hideClose {
  display: none;
}

.fadeText {
  -webkit-mask-image: linear-gradient(to left, transparent, black 8px);
  mask-image: linear-gradient(to left, transparent, black 8px);
}

