@import 'styles/variables';

.avatar {
    width: $avatar-size;
    height: $avatar-size;
    border-radius: $avatar-size;
    // margin-right: -6px; <- was hack for multiplayer avatars in hlist entity row items
    overflow: hidden;
    background: white;
    color: white;
    font-size: 10px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.avatarForeground {
    width: $avatar-size;
    height: $avatar-size;
    border-radius: $avatar-size;
    overflow: hidden;
    color: white;
    font-size: 10px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.avatarWhiteBorder {
    border: white solid 2px;
}

.avatarBlueBorder {
    border: $browsed solid 2px;
}

.avatarImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.avatarUnfocused {
    opacity: 0.5;
}

.avatarsContainer {
    display: flex;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 10px 0 26px;
}

// .avatarBarrier {
//     &:before {
//         content: ' ';
//         width: 1px;
//         height: 18px;
//         background-color: $black-10;
//     }
// }