@import 'styles/variables';

.toast {
    z-index: 10000;
    position: fixed;
    bottom: 0;
    right: 1rem;
    width: 20rem;
    border: 1px solid $black-10;
    border-bottom: none;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    background: white;
    box-shadow: $elevation--high;
}

.toastHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    h6 {
        margin: 0;
    }
}

.toastToggleButton {
    margin-right: 4px;
}

.toastButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 3rem;

    svg path {
        fill: $black-80;
        fill-opacity: 1;
    }
}

.toastContent {
    font-size: 0.9rem;
    background-color: $white;
    max-width: 20rem;
    max-height: 12rem;
    overflow-y: auto;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.toastContentClosed {
    max-height: 0;
}

.fileItem {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    &:last-child {
        margin-bottom: 1rem;
    }

    .fileName {
        max-width: 15rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.fileItemSuccess {
    svg path {
        fill: $success;
    }
}

.fileItemError {
    svg path {
        fill: $error;
    }
}

.spinner {
    width: 1rem;
    height: 1rem;
    border: 2px solid #777;
    border-right-color: transparent;
}
