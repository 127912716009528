@import 'styles/variables';

.emptyEntityContainer {
    width: 100%;
    height: calc(100vh - 82px);
    background-color: rgba(232, 234, 237, 0.95);
}

.innerContainer {
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 620px;
    padding: 10px;
    > svg {
        opacity: 0.1;
    }
}