@use 'sass:math';

@import 'styles/variables.scss';

.ProseMirror-menubar {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    position: relative;
    min-height: 1em;
    color: #666;
    padding: 1px 6px;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid silver;
    background: white;
    z-index: 10;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: visible;
}

.ProseMirror::after {
    content: ' ';
    height: 200px;
    width: min(708px, 100%);
    display: block;
}

.ProseMirror {
    position: relative;
    height: 100%;
    width: min(708px, 100%);
}

.ProseMirror {
    word-wrap: break-word;
    white-space: pre-wrap;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    ul[data-type='taskList'] {
        list-style-type: none;
    }
    label > input[type='checkbox'] {
        display: none;
        &:hover + *:before {
            background-color: $black-5;
            cursor: pointer;
        }
    }
    label > input[type='checkbox'] + *::before {
        content: '';
        display: inline-block;
        vertical-align: bottom;
        width: 1rem;
        height: 1rem;
        border-radius: 2px;
        border-style: solid;
        border-width: 2px;
        border-color: $black-60;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-shrink: 0;
        transform: translateX(4px);
    }
    label > input[type='checkbox']:checked + * {
        color: $selected-100;
    }
    label > input[type='checkbox']:checked + *::before {
        content: '✓';
        color: white;
        text-align: center;
        background: $selected-100;
        border-color: $selected-100;
        padding-bottom: 1px;
    }
}

.iframe-wrapper {
    position: relative;
    padding-bottom: math.div(100, 16)*9%; // aspect ratio 16:9
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
        outline: 3px solid #68CEF8;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.ProseMirror pre {
    white-space: pre-wrap;
}

.ProseMirror li {
    position: relative;
    label {
        position: absolute;
        left: -22px;
        top: 6px;
    }
}

// .ProseMirror ul[data-type='taskList'] {
//     [data-checked='true'] {
//         text-decoration: line-through;
//         color: $black-30;
//     }
//     [data-checked='false'] {
//         text-decoration: none !important;
//     }
// }

.ProseMirror {
    li[data-checked='true'] > div > p {
        text-decoration: line-through;
        color: $black-30;
    }
    li .bedrock-block-taskItem[data-checked='false'] {
        text-decoration: none !important;
    }
}

.ProseMirror img {
    max-width: 100%;
    cursor: pointer;
}

.ProseMirror {
    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow-x: hidden;

        td,
        th {
            min-width: 1em;
            border: 2px solid #ced4da;
            padding: 3px 5px;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;

            >* {
                margin-bottom: 0;
            }
        }

        tr {
            // display: flex;

            div {
                // display: inline-block;
                width: 100%;
            }
        }

        th {
            font-weight: bold;
            text-align: left;
            background-color: #f1f3f5;
        }

        .selectedCell:after {
            z-index: 2;
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(200, 200, 255, 0.4);
            pointer-events: none;
        }

        .column-resize-handle {
            position: absolute;
            right: -2px;
            top: 0;
            bottom: -2px;
            width: 4px;
            background-color: #adf;
            pointer-events: none;
        }

        p {
            margin: 0;
        }
    }

    [data-type="emoji"] {
        img {
            width: 1em;
            height: 1em;
        }
    }
}

.table-cell-dropdown {
    z-index: 50;
    background-color: #ffffff;
    width: 100%;
    border-radius: 0.25rem;

    .button {
        display: flex;
        padding: 0.25rem 0.5rem;
        color: #000000;
        width: 100%;
        border-radius: 0.25rem;
        gap: 0.5rem;
        border: none;
        background: transparent;

        &:hover {
            background-color: #F3F4F6;
        }
    }
}

.table-cell-trigger-button {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 1px;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0.25rem;
    background-color: #000000;
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;
}

.table-row-controls {
    display: none;
    display: flex;
    position: absolute;
    z-index: 50;
    align-items: center;
    width: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-color: #4F46E5;
    cursor: pointer;
    
    &.hidden {
        display: none;
    }

    .table-row-remove-button {
        background-color: gray;
        position: absolute;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        margin-right: 0.5rem;
        font-size: 0.875rem;
        line-height: 1rem;
        border: none;
        border-radius: 4px;
        color: black;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }
}

.ProseMirror-hideselection *::selection {
    background: transparent;
}
.ProseMirror-hideselection *::-moz-selection {
    background: transparent;
}
.ProseMirror-hideselection {
    caret-color: transparent;
}

.ProseMirror-selectednode {
    outline: 2px solid #8cf;
}

/* Make sure li selections wrap around markers */

li.ProseMirror-selectednode {
    outline: none;
}

li.ProseMirror-selectednode:after {
    content: '';
    position: absolute;
    left: -32px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    border: 2px solid #8cf;
    pointer-events: none;
}
.ProseMirror-textblock-dropdown {
    min-width: 3em;
}

.ProseMirror-menu {
    margin: 0 -4px;
    line-height: 1;
}

.ProseMirror-tooltip .ProseMirror-menu {
    width: -webkit-fit-content;
    width: fit-content;
    white-space: pre;
}

.ProseMirror-menuitem {
    margin-right: 3px;
    display: inline-block;
}

.ProseMirror-menuseparator {
    border-right: 1px solid #ddd;
    margin-right: 3px;
}

.ProseMirror-menu-dropdown,
.ProseMirror-menu-dropdown-menu {
    font-size: 90%;
    white-space: nowrap;
}

.ProseMirror-menu-dropdown {
    vertical-align: 1px;
    cursor: pointer;
    position: relative;
    padding-right: 15px;
}

.ProseMirror-menu-dropdown-wrap {
    padding: 1px 0 1px 4px;
    display: inline-block;
    position: relative;
}

.ProseMirror-menu-dropdown:after {
    content: '';
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid currentColor;
    opacity: 0.6;
    position: absolute;
    right: 4px;
    top: calc(50% - 2px);
}

.ProseMirror-menu-dropdown-menu,
.ProseMirror-menu-submenu {
    position: absolute;
    background: white;
    color: #666;
    border: 1px solid #aaa;
    padding: 2px;
}

.ProseMirror-menu-dropdown-menu {
    z-index: 15;
    min-width: 6em;
}

.ProseMirror-menu-dropdown-item {
    cursor: pointer;
    padding: 2px 8px 2px 4px;
}

.ProseMirror-menu-dropdown-item:hover {
    background: #f2f2f2;
}

.ProseMirror-menu-submenu-wrap {
    position: relative;
    margin-right: -4px;
}

.ProseMirror-menu-submenu-label:after {
    content: '';
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid currentColor;
    opacity: 0.6;
    position: absolute;
    right: 4px;
    top: calc(50% - 4px);
}

.ProseMirror-menu-submenu {
    display: none;
    min-width: 4em;
    left: 100%;
    top: -3px;
}

.ProseMirror-menu-active {
    background: #eee;
    border-radius: 4px;
}

.ProseMirror-menu-active {
    background: #eee;
    border-radius: 4px;
}

.ProseMirror-menu-disabled {
    opacity: 0.3;
}

.ProseMirror-menu-submenu-wrap:hover .ProseMirror-menu-submenu,
.ProseMirror-menu-submenu-wrap-active .ProseMirror-menu-submenu {
    display: block;
}

.ProseMirror-icon {
    display: inline-block;
    line-height: 0.8;
    vertical-align: -2px; /* Compensate for padding */
    padding: 2px 8px;
    cursor: pointer;
}

.ProseMirror-menu-disabled.ProseMirror-icon {
    cursor: default;
}

.ProseMirror-icon svg {
    fill: currentColor;
    height: 1em;
}

.ProseMirror-icon span {
    vertical-align: text-top;
}
.ProseMirror-gapcursor {
    display: none;
    pointer-events: none;
    position: absolute;
}

.ProseMirror-gapcursor:after {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    width: 20px;
    border-top: 1px solid black;
    animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
    to {
        visibility: hidden;
    }
}

.ProseMirror-focused .ProseMirror-gapcursor {
    display: block;
}
/* Add space around the hr to make clicking it easier */

.ProseMirror-example-setup-style hr {
    padding: 2px 10px;
    border: none;
    margin: 1em 0;
}

.ProseMirror-example-setup-style hr:after {
    content: '';
    display: block;
    height: 1px;
    background-color: silver;
    line-height: 2px;
}

.ProseMirror ul,
.ProseMirror ol {
    padding-left: 30px;
}

.ProseMirror blockquote {
    padding-left: 1em;
    border-left: 3px solid #eee;
    margin-left: 0;
    margin-right: 0;
}

.ProseMirror-example-setup-style img {
    cursor: default;
}

.ProseMirror-prompt {
    background: white;
    padding: 5px 10px 5px 15px;
    border: 1px solid silver;
    position: fixed;
    border-radius: 3px;
    z-index: 11;
    box-shadow: -0.5px 2px 5px rgba(0, 0, 0, 0.2);
}

.ProseMirror-prompt h5 {
    margin: 0;
    font-weight: normal;
    font-size: 100%;
    color: #444;
}

.ProseMirror-prompt input[type='text'],
.ProseMirror-prompt textarea {
    background: #eee;
    border: none;
    outline: none;
}

.ProseMirror-prompt input[type='text'] {
    padding: 0 4px;
}

.ProseMirror-prompt-close {
    position: absolute;
    left: 2px;
    top: 1px;
    color: #666;
    border: none;
    background: transparent;
    padding: 0;
}

.ProseMirror-prompt-close:after {
    content: '✕';
    font-size: 12px;
}

.ProseMirror-invalid {
    background: #ffc;
    border: 1px solid #cc7;
    border-radius: 4px;
    padding: 5px 10px;
    position: absolute;
    min-width: 10em;
}

.ProseMirror-prompt-buttons {
    margin-top: 5px;
    display: none;
}
.editor {
    background: white;
    color: black;
    background-clip: padding-box;
    padding: 5px 0;
    margin-bottom: 23px;
}

// .ProseMirror p:first-child,
// .ProseMirror h1:first-child,
// .ProseMirror h2:first-child,
// .ProseMirror h3:first-child,
// .ProseMirror h4:first-child,
// .ProseMirror h5:first-child,
// .ProseMirror h6:first-child {
//   margin-top: 10px;
// }

.ProseMirror {
    padding: 30px 8px 4px 14px;
    line-height: 1.2;
    outline: none;
}

// .ProseMirror p { margin-bottom: 1em }

.editor-navbar-container {
    width: 100%;
    padding: 5.5px 2px;
    border-bottom: 1px solid $black-10;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    z-index: 1;
    background: white;
    flex: 0 1 auto;
}

.editor-box {
    padding: 15px;
}

.editor-navbar-container button.active {
    background-color: $black-10;
    &:hover {
        background-color: $black-20;
    }
}

.editor-container {
    border: 1px solid #ccc;
}

.editor-navbar-container button,
.link-popup button,
.editor-navbar-container .notes-control {
    position: relative;
    padding: 8px 6px;
    border: none;
    background: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-right: 2px;
    margin-left: 6px;
    &:hover {
        background-color: $black-10;
        .nested-menu {
            display: flex;
        }
    }
    span:first-child {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        color: #888;
    }
    svg * {
        fill: #888;
    }
}

.editor-navbar-container .notes-control .nested-menu-icon {
    font-size: 15px;
    font-weight: 600;
}

.editor-navbar-container .notes-control .nested-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: -3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px;
    background-color: white;
    border-radius: 3px;
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.15) 0px 9px 24px;

    button {
        margin: 0;
    }
}

.editor-navbar-container button, .editor-navbar-container .notes-control {
    padding: 0;
    height: 20px;
    width: 20px;
    font-size: 13px;
    span:first-child {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.editor-navbar-container button:disabled,
.editor-navbar-container .notes-control-disabled {
    opacity: 0.3;
    .nested-menu {
        display: none !important;
    }
}

.editor-navbar-container .notes-control-disabled * {
    cursor: default;
}

.bedrock-note-container {
    position: relative;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.base-or-native-note-container {
    height: calc(100% - 28px);
}

.bedrock-page-scroll-container {
    height: 100%;
    overflow: auto;
}

.bedrock-loading-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.bedrock-page-content {
    position: relative;
    margin: 0 auto;
    width: min(708px, 100%);
    font-size: 15px;
    line-height: 1.5;
    overflow-y: auto;
    flex: 1 1 auto;

    h1.bedrock-block-heading {
        margin-top: 2rem;
        margin-bottom: 4px;
        font-size: 2.5rem;
        // font-weight: 600;
        // line-height: 1.3;
        // color: inherit;
        // fill: inherit;
        // max-width: 100%;
        // width: 100%;
        // white-space: pre-wrap;
        // word-break: break-word;
        // caret-color: rgb(55, 53, 47);
        // padding: 3px 2px;
    }
    h2.bedrock-block-heading {
        margin-top: 1.4rem;
        margin-bottom: 1px;
    }
    h3.bedrock-block-heading {
        margin-top: 1rem;
        margin-bottom: 1px;
    }
    .bedrock-block-text {
        margin-top: 1px;
        margin-bottom: 1px;
        line-height: 1.5;
        color: inherit;
        fill: inherit;
        max-width: 100%;
        width: 100%;
        white-space: pre-wrap;
        word-break: break-word;
        caret-color: rgb(55, 53, 47);
        padding: 3px 2px;
    }
}

.bedrock-page-content > div:first-child {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

// .bedrock-page-content {
//   height: calc(100vh - 125px); // 125px = sum of heights: PM menu, entity frame, window frame
// }
// .entity-page .bedrock-page-content {
//   height: calc(100vh - 85px); // 85px = sum of heights: PM menu, window frame
// }

.bedrock-block-checkbox {
    // TODO
}

.bedrock-block-orderedList {
    // TODO
}

.bedrock-block-unorderedList {
    // TODO
}

.link-popup-add {
    display: flex;
    align-items: center;
    padding: 6px;
}

.add-link-form {
    display: flex;
}

.link-popup-edit {
    min-width: 200px;
    padding: 10px;
    input {
        // width: 256px;
        margin-bottom: 5px;
    }
}
.link-popup-edit .add-link-form {
    display: block;
}
.link-popup-edit .add-link-control {
    margin-top: 20px;
}
.link-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button-icon-container {
        cursor: pointer;
        max-width: 80%;
    }
    .link {
        // style={{ display: 'flex', alignItems: 'flex-start', wordBreak: 'break-word'}}
        display: flex;
        align-items: flex-start;
        word-break: break-word;
    }
}
.add-link-control {
    display: flex;
    justify-content: space-between;
}
.link-popup-edit label {
    color: $text;
}
.button-icon {
    margin-right: 0 !important;
    display: flex;
    span, svg {
        margin-right: 0 !important;
    }
}
.edit-button-container {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #00000030;
    display: flex;
    align-items: flex-start;
    .textButtonContainer {
        height: 23px;
    }
}

// .add-link-form label {
//     margin: 0;
//     margin-right: 2px;
// }

.add-link-form .primaryButton {
    color: white;
}
.add-link-form {
    .add-button {
        margin-left: 10px;
    }
}

.link-menu-bubble .primaryButton {
    max-width: 500px !important;
}

.mention-items {
    position: relative;
    border-radius: 0.25rem;
    background: white;
    color: rgba(black, 0.8);
    overflow: hidden;
    font-size: 0.9rem;
    box-shadow:
      0 0 0 1px rgba(0, 0, 0, 0.1),
      0px 10px 20px rgba(0, 0, 0, 0.1),
    ;
  }

  .mention-item {
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    padding: 0.2rem 0.5rem;
    color: $text;

    &.is-selected,
    &:hover {
      color: $selected-100;
      background: $selected-10;
    }
  }

  .mention {
    color: $selected-100;
    background-color: $selected-10;
    border-radius: 0.3rem;
    padding: 0.1rem 0.3rem;
  }
  .current-user {
      color: $selected-100;
      background-color: $attention-t;
  }
.drawing-node {
    cursor: crosshair;
    svg {
        border: solid 1px $black-10;
        background-color: $black-3;
    }
    // path {
    //   fill: none;
    //   stroke-linecap: round;
    //   stroke-linejoin: round;
    // }
}
.editor-color-button {
    position: relative;
    min-width: 28px;
    div {
        position: absolute;
        width: 16px;
        height: 7px;
        bottom: 5px;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid $black-10;
    }
    div:first-child {
        top: 1px;
        transform: translate(-30%, -50%);
        border: none;
    }
}

.editor-bubble {
    background-color: white;
    border: $black-10;
    border-radius: 10px;
    box-shadow:
      0 0 0 1px rgba(0, 0, 0, 0.1),
      0px 10px 20px rgba(0, 0, 0, 0.1),
    ;
    border-radius: 0.25rem;
}

.ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: $black-20;
    pointer-events: none;
    height: 0;
  }
.bedrock-block-codeblock {
    background: #f2f2f2;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
}
.bedrock-block-codeline {
    background: #f2f2f2;
    padding: 2px 5px;
    border-radius: 4px;
    border: 1px solid #e7e7e7;
    // line-height: 2.3;
}

.copy-link-button {
    &:first-child {
        margin-right: 3px;
    }
    padding: 2px 5px !important;
}

.bedrock-link-item {
    cursor: pointer;
}
