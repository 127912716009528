@import 'styles/variables';

$menu-min-width: 230px;
$menu-max-width: 300px;
$name-input-width: 170px;
$menu-padding: 4px;
$menu-border-radius: 10px;
$item-border-radius: 6px;

.selectedSVG {
    rect[stroke] {
        stroke: white;
    }
    rect[fill] {
        fill: white;
    }
    path[fill] {
        fill: white;
    }
    path[stroke='white'] {
        stroke: $selected-100;
    }
}

.contextMenu {
    --pos-x: 0;
    --pos-y: 0;
    position: fixed;
    display: none;
    z-index: 1000;
    margin: 0px;

    transform: translateX(min(var(--pos-x), calc(100vw - 100% - 10px)))
        translateY(min(var(--pos-y), calc(100vh - 100% - 10px)));

    li {
        position: relative;
        list-style-type: none;
    }

    &.visible {
        display: block;
    }

    li:hover,
    li:has(menu > .safeZone:hover) {
        > .submenu {
            display: block;
            width: 100%;
        }
    }
}

// just adding blur does not always work
// https://stackoverflow.com/questions/60997948/backdrop-filter-not-working-for-nested-elements-in-chrome
.menu::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(30px) !important;
    border-radius: $menu-border-radius;
}

.menu {
    padding: $menu-padding 0;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    border-radius: $menu-border-radius;
    min-width: $menu-min-width;
    max-width: $menu-max-width;
    max-height: 90vh;

    * {
        position: relative;
        background: transparent;
    }
}

.menuWide {
    max-width: $menu-max-width * 2;
}

.header {
    padding: 4px 15px;
    color: $text;
    font-size: 13px;
    line-height: 21px;
    font-weight: 700;
}

.subText {
    padding: 2px 23px;
    color: $selected-100;
    font-size: 11px;
    line-height: 21px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subSubText {
    @extend .subText;
    margin-top: -15px;
    font-size: 10px;
}

.itemBase {
    transition: all 0.1s;
    padding: 4px 10px;
    font-size: 13px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: $text;
    cursor: default;
    margin: 0px $menu-padding;
    border-radius: $item-border-radius;
    width: calc(100% - $menu-padding * 2);
    text-overflow: ellipsis;

    min-width: 0px;
    flex: 1 1 auto;

    > div:first-child {
        // Icon
        margin-right: 10px;
        display: flex;
        justify-self: center;
    }

    div:last-child {
        // Shortcut
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
    }

    &:active:not(.noHover) {
        color: $text;
        text-decoration: none;
        background-color: $white;
    }

    &.selected,
    &:hover:not(span, .noHover) {
        color: $white;
        text-decoration: none;
        background-color: $selected-100;
    }

    &:has(svg[data-3d-icon]) svg {
        filter: drop-shadow(0px 0px 0.25px rgb(0 0 0 / 0.05)) drop-shadow(0px 1px 1px rgb(0 0 0 / 0.3));
    }

    // to ensure event firing only on parent element
    *:not(.allowEvents) {
        pointer-events: none;
    }
}

.multItemWrapper {
    composes: itemBase;
    display: flex;
    justify-content: space-between;
    &:hover .multItemButtonsWrapper {
        visibility: visible;
    }
    &:hover {
        background-color: $selected-10-t;
    }
}

.multItemButtonsWrapper {
    display: flex !important;
    justify-content: space-between;
    visibility: hidden;
    align-items: center;
}

.multItemButton {
    composes: itemBase;
    color: $selected-100;
    margin: 0;
    margin-right: 0;
    padding: 0px;
    width: 50px;
    text-align: center;
    display: block;

    &:hover:not(span) {
        color: $white;
        background-color: $selected-100;
    }
}

.item {
    composes: itemBase;
    &:not(span, .noHover) {
        &.selected,
        &:hover {
            .itemHotkey {
                color: $black-10;
            }
        }

        &.selected :not(svg[data-3d-icon]),
        &:hover div:not([data-icon-type='integration-icon']) {
            @extend .selectedSVG;

            // quick hack tp exclude some edgecases
            // should just make those into classes and assign them correctly to icons
            path:not([stroke], [fill='white']) {
                fill: white;
            }
            path:not(rect + path, path + path[fill], circle + path, :has(+ path)) {
                &[stroke] {
                    stroke: white;
                }
                &[fill='white'] {
                    fill: $selected-100;
                }
            }
        }
    }

    .itemIcon {
        margin-right: 4px;

        > svg {
            width: 16px;
            height: 16px;
        }
        img {
            width: 14px;
            height: 14px;
            border-radius: 2px;
            object-fit: cover;
            border: 0.5px solid #e6e6e6;
        }
    }

    &.selected * {
        pointer-events: none;
    }
}

.item[data-disabled='true'] {
    &:hover {
        color: $text;
        background-color: transparent;
    }
}

.itemLabel {
    min-width: 0px;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    white-space: nowrap;
}

.itemIcon {
    display: block;
    margin-right: 10px;
    color: $black-70;
}

.itemHotkey {
    font-size: 11px;
    color: #999;
}

.itemChosen {
    composes: itemBase;
    &:not(span, .noHover) {
        text-decoration: none;
        background-color: $black-10;
    }
}

.itemChecked {
    composes: itemBase;
    .checkedIcon {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
}

.searchContainer {
    margin: 0 $menu-padding;
}

.highlighted {
    font-weight: bold;
}

.searchInput {
    width: 100%;
    padding: 4px 8px;
    border: none;
    border-radius: $item-border-radius;
    background: $white;
    font-size: 14px;
    line-height: 21px;
    color: #333;
    outline: none;
    box-shadow: 0 0 0 1px $black-10-t;

    &:focus {
        box-shadow: 0px 0px 0 1px $selected-100 !important;
    }
}

.submenu {
    composes: menu;
    display: none;
    position: absolute;
    left: calc(100% - 2px);
    top: 0px;
    z-index: 10000;
}

.submenuParent {
    display: flex;
    align-items: center;
    color: $text;
    cursor: default;
    font-size: 13px;
    line-height: 21px;
    border-radius: $item-border-radius;
    flex: 1 1 auto;
    position: relative;

    &:hover {
        .itemHotkey {
            color: $black-10;
        }
        & > span {
            & > .itemIcon,
            & > .submenuParentExpandIcon,
            & > .submenuParentIcon:not(:has(> div[data-icon-type='integration-icon'])),
            & > svg {
                @extend .selectedSVG;

                path {
                    fill: white;
                }
            }

            color: $white !important;
            text-decoration: none;
            background-color: $selected-100;
        }
    }

    &.selected {
        .itemHotkey {
            color: $black-10;
        }
    }
}

.submenuParentExpanded {
  > .submenu {
    display: block;
    width: 100%;
  }
}

.submenuParentIcon {
    margin-right: 10px;
    display: flex;
    justify-self: center;

    div[data-icon-type='integration-icon'] {
        overflow: visible;

        > span {
            display: block;
        }

            > div {
            position: absolute;
        }
    }
}

.submenuParentLabel {
    max-width: 170px;
    width: 80%;
    display: flex;
    gap: 10px;
}

.submenuBaseLabelPart {
    text-transform: capitalize;
}

.submenuExtraLabelPart {
    font-style: italic;
    opacity: 0.7;
    text-overflow: ellipsis;
    overflow: hidden;
}

.submenuParentExpandIcon {
    margin-left: auto;
}

.entityTypeRow {
    display: flex;
    justify-content: space-between;
    padding: 0 $menu-padding;
    gap: $menu-padding;
    .item {
        margin: 0;
        width: auto;
        padding: 8px;
        display: flex;
        justify-content: center;
    }
}

.deleteEntity {
    composes: itemBase;
    color: $error;
    rect[stroke] {
        stroke: $error;
    }
    path[fill] {
        fill: $error;
    }
    path[stroke] {
        stroke: $error;
    }
    &.selected,
    &:hover:not(span, .noHover) {
        color: $error;
        text-decoration: none;
        background-color: $error-t;
        path[fill='white'] {
            fill: $error-15;
        }
    }
    .itemLabel {
        min-width: 0px;
        flex: 1 1 auto;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
        white-space: nowrap;
    }
}

.itemSystemMenu {
    composes: itemBase;
    cursor: pointer;

    &.disabled {
        &.selected,
        &:hover:not(span, .noHover) {
            color: #333;
            text-decoration: none;
            background-color: $black-10;
        }

        * {
            opacity: 0.8;
        }
    }
}

.itemHeader {
    padding: 4px 17px;
    opacity: 0.5;
    font-size: 13px;
}

.itemText {
    font-size: 13px;
    width: 100px;
}

.systemMenuItemChiplet {
    font-size: 11px;
    margin-left: auto;
    background: $selected-100;
    padding: 3px;
    color: $white;
    border-radius: 8px;
}

.itemNoHover {
    padding: 4px 10px;
    margin: 0px $menu-padding;
    font-size: 13px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $text;

    &:active {
        color: $text;
        text-decoration: none;
        background-color: $white;
    }

    &:hover {
        color: $text;
        text-decoration: none;
        background-color: transparent !important;
    }
}

.divider {
    border-top: 1px solid $black-10;
    margin: $menu-padding 0;
}

.switch {
    vertical-align: middle;
    margin-left: 10px;
}

.contextMenuSubheader {
    font-size: 13px;
    font-weight: 500;
    color: $selected-100;
    width: $name-input-width;
    height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding-left: 2px;
    transition: background-color 0.1s ease-in;

    & > input[type='text'] {
        font-weight: 500;
        color: $text;
        border: none;
        max-width: $name-input-width;
        width: 100%;
        line-height: 1.1rem;
        overflow: hidden;
        pointer-events: all;
    }
}

.inputHover:not(form) {
    &:hover {
        background-color: $black-10;
        border-radius: 2px;
    }
}

form.contextMenuSubheader {
    box-shadow: 0 0 0 1px $selected-100;
    padding-left: 0;
    border-radius: 2px;
}

.pointer {
    cursor: pointer;
}

.blink {
    animation: blinkOnClick 0.2s linear forwards;
}

@keyframes blinkOnClick {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0.7;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
