@import 'styles/variables.scss';

// breaks default styles, maybe delete?
textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
    border: none;
}

.drop-list {
    min-width: 0px !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999999;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999999;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #999999;
}

.popover-body {
  padding: 0;
  border-radius: 4px;
}

.list-group-item {
  height: 30px;
  line-height: 28px;
  padding: 0 14px;
  margin: 0;
  border: none;
}

.dropdown-toggle:after { content: none !important; }

.nav-tabs .nav-link {
    border-top: none;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.dropdown-item:focus, .dropdown-item:hover {
    background-color: white;
}

// Nav - Vertical Tabs
.nav {
    gap: 1px;
}
.tab-content {
    > div {
        height: 100%;

    }
}
// https://github.com/react-bootstrap/react-bootstrap/issues/2081
.nav-pills .nav-link.active {
    background-color: $black-20;
}
.bs-tooltip-auto[x-placement^="right"] span.arrow::before, .bs-tooltip-right .arrow::before {
     border-right-color:rgba(0, 0, 0, 0.8);
}
.bs-tooltip-auto[x-placement^="bottom"] span.arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color:rgba(0, 0, 0, 0.8);
}
.bs-tooltip-auto[x-placement^="left"] span.arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color:rgba(0, 0, 0, 0.8);
}
.bs-tooltip-auto[x-placement^="top"] span.arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color:rgba(0, 0, 0, 0.8);
}
