@import 'styles/variables.scss';

.mainContainer {
    min-width: 708px;
    max-height: 95vh;
    transition: 0.3s ease-out;
    display: flex;
    justify-content: center;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    30% { opacity: 0; }
    100% { opacity: 1; }
}

.avatarNameJobContainer {
    padding: 40px;
    transition: height width 2s;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
    a {
        color: inherit;
        text-decoration: underline;
    }
}

.avatarNameJobContent {
    max-width: 300px;
    margin: 0 auto 16px;
}

.avatarNameJobInputsContainer {
    max-width: 300px;
    margin: 50px auto;

    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
        font-size: 14px;
        background: white;
    }
}

.stepControlContainer {
    display: grid;
    grid-template-columns: 100px auto;
    column-gap: 10px;
    row-gap: 10px;
    max-width: 300px;
    margin: 0 auto;
    button {
        height: 38px;
    }
}

.singleContinue {
    width: 100%;
    height: 38px;
}

.cardsContainer {
    display: grid;
    grid-template-columns: 235px 235px 235px;
    column-gap: 40px;

    justify-content: center;
    margin: 77px 0 90px;

    &.twoCol {
        grid-template-columns: 240px 240px;
    }
}

.maxWidth {
    display: flex;
    gap: 40px;
    max-width: 500px;
    margin: 20px 0;
}

.selectCard {
    padding: 20px 20px 40px;
    border-radius: 10px;
    box-shadow: 0 0 0 1px $black-5;
    position: relative;

    transition: all 0.3s;

    cursor: pointer;
    &:hover {
        background: $black-5;
    }
    &.activeCard {
        box-shadow: 0 0 0 4px white, 0 0 0 6px $selected-100;
    }

    .cloudContainer {
        position: relative;
        height: 93px;
        margin-bottom: 14px;
        pointer-events: none;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .innerIcon {
            top: 55%;
            width: 35px;
            height: 35px;
            border-radius: 100%;
            overflow: hidden;
            object-fit: cover;
        }
    }
}

.checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 50%;
    bottom: 10px;
    width: 100%;

    transform: translate(-50%);

    .checkboxIconContainer {
        display: flex;
        margin-right: 6px;
        svg {
            path {
                fill: $selected-100;
            }
        }
    }
    .checkboxLabel {
        color: $selected-100;
    }
}

.galacticaCard {
    position: relative;
    opacity: 0.5;

    transition: all 0.3s;
    cursor: pointer;
    img {
        left: 15px;
        position: relative;
        pointer-events: none;
    }
    .checkboxIconContainer > svg {
        path {
            fill: $black-50;
        }
    }
    &.galacticaCardActive {
        opacity: 1;
    }
}

.blueText {
    color: $selected-100;
    cursor: pointer;
}

.appLinkButtonContainer {
    position: relative;
    height: 320px;

    .appLinkButton {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.cardButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: auto;
    padding: 20px 20px 0 20px;
    margin: 60px auto;

    box-shadow: 0 0 0 1px $black-5;
    border-radius: 10px;

    text-decoration: none !important;

    transition: all 0.3s;
    cursor: pointer;

    &:hover {
        background: $black-10;
    }

    img {
        width: 60px;
        height: 60px;

        margin: 0 auto 10px;
    }
}

.teamPic {
    margin-bottom: 20px;
    width: 100%;
}

.inviteEmailInput {
    display: flex;
    align-items: center;
    button {
        margin-left: 10px;
    }
}

.emailList {
    height: 0;
    opacity: 0;

    margin-top: 20px;

    border: 1px solid $black-10;
    border-radius: 10px;

    overflow: auto;
    transition: all 0.3s;

    &.showList {
        height: 150px;
        opacity: 1;
    }

    .email {
        &:nth-child(even) {
            background: $black-5;
        }
        position: relative;
        display: flex;
        align-items: center;
        div {
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        padding: 0 6px;

        cursor: pointer;

        transition: all 0.3s;

        button {
            position: absolute;
            left: -15px;

            opacity: 0;
            transition: all 0.3s;
        }
        &:hover {
            background-color: $error-t;
            padding-left: 27px;
            button {
                left: 6px;
                opacity: 1;
            }
        }

    }
}

.inviteButton {
    position: relative;
}

.emoji {
    animation: splash 0.3s normal 1 ease-in-out;
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
}

@keyframes splash {
    from {
        transform: scale(1.1, 1.1);
    }
    50% {
        box-shadow: 0 0 0 30px #ffffff36;
        background-color: #ffffff36;
        transform: scale(1.5, 1.5);
    }
    to {
        background-color: #ffffff00;
        box-shadow: 0 0 0 100px #ffffff00;
        transform: scale(1, 1);
    }
}

.inputLabel {
    margin: 30px 0 10px;
    color: $black-70;
}
.teamSizeContainer {
    display: flex;
    box-shadow: 0 0 0 0.5pt #ccc;
    border-radius: 5px;
    height: 35px;

    .teamSizeButton {
        flex: 1;
        opacity: 0.5;
        &.active {
            opacity: 1;
        }
    }
    &:hover {
        .teamSizeButton {
            opacity: 1;
        }
    }
}

.downloadsStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.downloadsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    padding: 30px 40px;
    border-radius: 25px;

    background: linear-gradient(0deg, #F7F7F7, #F7F7F7), linear-gradient(0deg, #FFFFFF, #FFFFFF);

}

.appIconWrapper {
    width: 112px;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    span {
        color: #646464;
        font-family: SF Pro;
        font-size: 12px;
        font-weight: 510;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;

    }
}
.downloadButtonsContainer {
    display: flex;
    gap: 14px;

}

.downloadButton {
    display: flex;
    align-items: center;
    gap: 7px;

    outline: none;
    border: none;

    color: white;
    background: linear-gradient(180deg, #C4C8CA 0%, #A2A6A8 100%);

    span {
        display: block;
        white-space: nowrap;
        font-weight: 600;
        line-height: 26px;
    }

    border-radius: 4px;
    padding: 6px 12px;

    svg {
        fill: white;
        height: 16px;
    }
}

.primary {
    background: linear-gradient(180deg, #0B3D52 0%, #001926 100%);
}

.dropdownMenu {
    padding: 4px;
}
.dropdownLink {
    text-decoration: none !important;
    border-radius: 4px;
    padding: 4px 8px 4px 30px;

    &:hover {
        background: $black-10;
    }

    .dropdownLinkTitle {
        display: block;
        font-weight: 600;
    }
    .dropdownLinkSubtitle {
        display: block;
        font-size: 12px;
        line-height: 12px;
        color: $black-50;
    }
}
  