@import 'styles/variables.scss';
@import 'styles/mixins.scss';
// combines KVMultiChunk focused forms.module.scss with old pages/forms.module.scss
// WIP - still combining these here
// ---------------------------------

// --------------------------------
// all used in KVMultiChunk.tsx
// --------------------------------

.propertyRow {
    // position: relative;
    padding: 4px 0px;
    border-radius: 4px;
    border: 1px solid $white-t;
    display: grid;
    align-items: center;
    grid-template-columns: 20px 30% calc(70% - 20px);
    margin-left: -20px;
    transition: padding-bottom 0.15s cubic-bezier(0, 0, 0.2, 1);
    width: calc(100% + 20px);
    position: relative;
    .dragHandle {
        opacity: 0;
        display: flex;
        align-items: center;
    }
    &:hover {
        background-color: $selected-15-t;
        .dragHandle {
        opacity: 0.5;
        background: none;
        cursor: grab;
        }
    }
    &.active {
        background-color: $selected-15-t;
        border: 1px solid $selected-100;
    }
    &.dragged {
        opacity: 0.1;
        pointer-events: none;
    }
    &.hovered {
        padding-bottom: 45px;
        &::after {
        content: ' ';
        position: absolute;
        bottom: 4px;
        width: 100%;
        height: 37px;
        background-color: $selected-15-t;
        border-radius: 4px;
        }
        .dragHandle {
        opacity: 0 !important;
        }
    }
    &.blocked {
        pointer-events: none;
    }
    &.blockHoverStyles {
        &:hover {
        background-color: unset !important;
        }
    }
    &.locked {
        &:hover {
        background-color: unset !important;
        .dragHandle {
            opacity: 0 !important;
        }
        }
    }
}
.addPropertyMenuContainer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    gap: 12px;

    button {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.addPropertyButton {
    flex-grow: 1;
}

.propertyNameButton:disabled {
    color: rgba(55, 53, 47, 0.4);
    background-color: #f7f7f7;
}

.propertyNameButton:active {
    border-color: #bfbfbf !important;
    background-color: #bfbfbf !important;
}
.propertyNameButton {
    svg * {
        fill: $black-50;
    }
}

.noPointerEvents {
    pointer-events: none;
}

.lockButton {
    width: 22px;
    margin-right: 10px;
}

.propertyNameIcon {
    // position: absolute;
    // top: 3px;
    // left: 8px;
    // text-align: center;
}

.propertyNameLabel {
    // position: absolute;
    // top: 5px;
    // left: 30px;
    // padding-right: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 85%;
}

.propertyValue {
    margin-bottom: 0px !important;
    input {
        background: none;
    }
}

.removeCol {
    display: flex;
    align-items: center;
    justify-content: center;
}

.removeFormGroup {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0;
}

.removeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    font-size: 20px;
}

.objectProperty {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

    button {
        display: flex;
        flex-basis: auto;
        align-items: center;
        padding: 2px 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: left;
        width: 100%;
        gap: 10px;
        position: relative;
        svg {
            flex-shrink: 0;
        }
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.dropList {
    input {
        width: 100%;

        &:hover {
            background-color: $white-transparent-90;
        }

        &:focus {
            background-color: $white;
        }

        &:focus,
        &:hover {
            -webkit-appearance: auto;
            -moz-appearance: auto;
            appearance: auto;
        }
        .form-group {
            margin-bottom: 0;
        }
    }
}

.dropList .drop-list {
    min-width: 0 !important;
}

.ghostInput:hover {
    transition: none;
    /* border: solid 1px #999; */
    box-shadow: rgb(15 15 15 / 10%) 0 0 0 1px inset !important;
}
.ghostInput:focus {
    box-shadow: $outline-input-focus !important;
}
.ghostInput[type="date"] {
    color: #6c757d;
}

.submitRow {
    margin-left: 10px;
}

.error {
    color: #dc3545;
    font-size: 14px;
    margin-left: 10px;
    // position: absolute;
}

.popover {
    border: none;
    box-shadow: rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 15%) 0px 9px 24px;
}

.formGroup1 {
    margin: 0;
    padding: 0;
}

.listItem1 {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.listItem2 {
    height: 15px;
    line-height: 15px;
    font-size: 11px;
    color: rgba(55, 53, 47, 0.4) !important;
}

.listItem3 {
    height: 46px;
    background-color: #fff !important;
    cursor: auto !important;
    border-radius: 0px;
    padding-bottom: 8px;
}

.listItem4 {
    height: 35px;
}

.objectSection {
    position: relative;
}

.blobSection {
    composes: objectSection;
}

.typeSection {
    composes: objectSection;
    border-radius: 4px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    margin-top: 20px;

    &.typeSectionLocked {
        box-shadow: 0 0 0 0 $white-t;
        //background-color: $black-3;

        .schemaNameBar {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        .schemaNameBar,
        .objectProperty {
            background-color: $black-3;
        }

        .objectProperty {
            position: relative;
            overflow: visible;

            button {
                z-index: 2;
            }

            &:after {
                position: absolute;
                content: " ";
                bottom: -6px;
                left: -1px;
                right: 0;
                top: -20px;
                background-color: $black-3;
                z-index: 1;
                border-bottom-left-radius: 4px;
            }
        }
    }
}

.schemaName {
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 3px;
}

.schemaNameBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 2px;
    padding: 10px 20px 10px 7px;
}

.nameInput:focus, .nameInput:active {
    // box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px inset !important;
    background-color: #f7f7f7;
    box-shadow: $outline-input-focus !important;
}

.nameInput:hover {
    box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px inset;
    background-color: $black-5;
}


// --------------------------------
// From old pages/forms.module.scss
// ---------------------------------
@import 'styles/variables.scss';

.addForm {
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.container {
    font-size: 14px;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.buttonRow {
    text-align: right;
    margin-top: 30px;
}

.formContainer {
    min-width: 300px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
}

.formGroup {
    // margin-top: 30px;
    margin-bottom: 12px;
}

.formRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
}


.formLabel {
    padding: 3px 0px;
    margin: 0;
    /* Black 40% - Transparent */
    color: rgba(0, 0, 0, 0.4);
}

/* Forms ---------------------------- */
/* Form Input - DEFAULT */
.formControl {
    transition: all 0.2s;
    // height: 32px;
    border: none;
    box-shadow: 0 0 0 .5pt $black-20;
    box-sizing: border-box;
    font-size: 16px;
    padding: 6px 10px;
    /* Black 3% (Input Default) */
    background: #F7F7F7;
    border-radius: 4px;
    &:hover:not(:focus) {
        /* Black 5% (Drag zone, Input hover) */
        background: #F2F2F2;
    }
    &:focus {
        /* Selected 100% */
        // border: 1px solid #00A0F7;
        box-shadow: 0 0 0 1.5pt #00A0F7 !important;
        box-sizing: border-box;
        border-radius: 4px;
    }
    &::placeholder {
        color: $black-40;
    }
    &.small {
        font-size: 12px;
        line-height: 20px;
    }
}

.inputLabel {
    width: 100%;
}

/* Buttons */

.signupButton {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 7px;

    transition: all .4s ease-in-out;
    background: linear-gradient(180deg, #25BEFF 0%, #00A0F7 100%);
    box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.05), inset 0px -0.5px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;

    // if icon in button:
    flex: none;
    order: 1;
    flex-grow: 0;
    &:hover {
        background: linear-gradient(180deg, #00A0F7 100%, #00A0F7 100%);
        box-shadow: none;
        color: white;
    }
}

.error {
    color: #dc3545;
}

/* Type --------------- */

/* Heading */
/* TODO */

/* Body */
/* Text M */
.textMMedium {
    font-family: Inter, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* "paragraph" spacing */
    margin-bottom: 13px;
}

.textMSemiBold {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* "paragraph" spacing */
    margin-bottom: 13px;
}

.textMBold {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    /* "paragraph" spacing */
    margin-bottom: 13px;
}

/* Text S */
.textSMedium {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* "paragraph" spacing */
    margin-bottom: 12px;
}

.textSSemiBold {
    font-family: Inter, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    /* "paragraph" spacing */
    margin-bottom: 12px;
}
.textSBold {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    /* "paragraph" spacing */
    margin-bottom: 12px;
}

/* Text XS */
.textXSMedium {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* "paragraph" spacing */
    margin-bottom: 11px;
}

.textXSSemiBold {
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    /* "paragraph" spacing */
    margin-bottom: 11px;
}
.textXSBold {
    font-weight: bold;
    font-size: 13px;
    line-height: 21px;
    /* "paragraph" spacing */
    margin-bottom: 11px;
}

/* Text XXS */
.textXXSMedium {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* "paragraph" spacing */
    // margin-bottom: 10px;
}

.textXXSSemiBold {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    /* "paragraph" spacing */
    margin-bottom: 10px;
}

.textXXSBold {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    /* "paragraph" spacing */
    margin-bottom: 10px;
}

/* Miscellaneous */
.divider {
    width: 100%;
    height: 1px;
    border-bottom: solid 1px #E6E6E6; /* Black 10% (Borders) */
    margin-top: 18px;
    margin-bottom: 30px;
}

// SIGNUP

.signupContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    color: black;
}

.surveyContainer {
    composes: signupContainer;
    max-width: 80vw;
    width: calc(80vw - 10px);
    max-height: 90vh;
    height: calc(90vh - 10px);
}


.signupInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.signupForm {
    display: flex;
    width: 362px;
    padding: 16px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @include mobile {
        width: 100%;
    }
}

.loginContainer {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.saveSchemaContainer {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: right;
}

.saveSchemaButton {
    z-index: 2;
    border: none;
    background-color: white;
    color: $black-80;
    font-size: 14px;
}

.saveSchemaButton:hover {
    background: $gray-secondary !important;
    color: rgba(0,0,0,0.4) !important;
}

.saveSchemaInput {
    border: 1px solid hsl(0, 0%, 80%);
    padding: 0px 20px;
    border-radius: 5px;
    z-index: 2;
    height: 2em;
}

.iconButton {
    border: none;
    background: none;
    &:hover {
        background-color: $gray-secondary;
    }
}

.checkBox {
    height: 15px;
    width: 15px !important;
    margin: 9px;
}

.ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.searchInputField {
    max-width: 100%;
    min-width: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px !important;
}

.searchCloseButton {
    height: 22px;
    width: 22px;
    svg {
        width: 15px;
        height: 15px;
    }
}

.addMemberButton {
    width: 100%;
    height: 30px;
    gap: 6px;
    margin: 10px 0px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addMemberDialog {
    box-shadow: $shadow-modal-medium;
    border-radius: 5px;
}
