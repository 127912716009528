@import 'styles/variables';

.quickCreateWrapper {
    width: 250px;
}

.mainWrapper {
    width: 700px;
    max-width: 80vw;
}

.header {
    margin: -20px;
    padding: 20px 20px 10px;
    border-bottom: 1px solid $black-10;
    margin-bottom: 20px;
}

.integration {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.integrationIconTitleWrapper {
    display: flex;
    align-items: center;
}

.integrationIcon {
    border: 1px solid $black-10;
    border-radius: 30px;
    width: 31px;
    height: 31px;
    position: relative;
    margin-right: 10px;
    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.integrationConnectBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    position: relative;
    .disabledText {
        position: absolute;
        top: 105%;
    }
}

.integrationButton {
    width: 250px !important;
}

.success {
    color: $success;
}

.instructionList {
    margin: 0 10px;
}

.userFriendlyInput {
    width: 100%;
    font-size: 16px;
    line-height: 2;
    padding: 6px 10px;
    margin: 1rem 0;
}
