@import 'styles/variables';

.table td {
    width: 33%;
}

.table th {
    width: 33%;
    border-top: none !important;
}

.highlight {
    background-color: $gray-secondary;
}

.button {
    width: 100%;
    max-width: 100%;
    margin: auto;
}

.lastRow td {
    border-bottom: none;
}