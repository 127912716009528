@import 'styles/variables';

.base {
    font-family: Inter, Helvetica, sans-serif;
    font-style: normal;
    color: $text;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.left {
    text-align: left;
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}

.h1 {
    font-size: 50px;
    font-weight: bold;
    line-height: 1.24;
}

.h2 {
    font-size: 28px;
    line-height: 1.33;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 12px;
}

.h3 {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 6px;
}

.h4 {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.44;
}

.h5 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
}

.h6 {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.53;
}

.inactive {
    @extend .base;
    color: $black-40;
}
.body {
    @extend .base;
}

.normal {
    font-weight: 500;
}
.bold {
    font-weight: bold;
}
.semiBold {
    font-weight: 600;
}
.m {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 13px;
}
.s {
    font-size: 14px;
    line-height: 22px;
    // margin-bottom: 12px;
}
.xs {
    font-size: 12px;
    line-height: 22px;
    // margin-bottom: 11px;
}
.xxs {
    font-size: 10px;
    line-height: 20px;
    // margin-bottom: 10px;
}
.noMargin {
    margin: 0;
}
