@import 'styles/variables';

.avatarForeground {
    border-radius: 22px;
    overflow: hidden;
    color: white;
    font-size: 10px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: skyblue;
    cursor: default;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
