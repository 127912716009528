// imported in _app.tsx
@import 'styles/variables.scss';

.flexible-panel {
    position: absolute;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: $shadow-modal-medium;
}

$panel-header-height: 50px;
.panel {
    position: static;
    display: flex;
    flex-direction: column;
    height: 100%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid $black-10;
    overflow: hidden;
    border-radius: 5px;
    background-color: $white-transparent-90;
    .panelHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        // height: $panel-header-height !important;
        position: sticky;
        top: 0;
        border-bottom: 1px solid $black-20;
        z-index: 2;
        width: 100%;
        .panelTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 0;
            gap: 8px;
            h6 {
                margin-bottom: 0px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        // button {
        //     color: $black-50;
        //     background-color: transparent;
        //     height: 30px;
        //     width: 30px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     &:hover {
        //         background-color: $black-10;
        //     }
        // }
    }
    .panelShortcuts {
        overflow-y: scroll;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
    .panelContent {
        padding: 0 !important;
        overflow: auto;
    }
    .panelButtons {
        display: flex;
        align-items: center;
        gap: 6px;
        button {
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .panelButton {
            margin-right: 0px;
            width: auto;
        }
    }
}
#__next {
    position: relative;
    // overflow: hidden;
}

.recentQuickNoteCard {
    // background-color: white;
    width: 100%;
    // height: 50px;
    padding: 5px 11px;
    // margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    // overflow: scroll;
    &.activeNote {
        background-color: $browsed;
        border: 1px solid $browsed;
    }
    &:hover {
        border: 1px solid $selected-100;
    }
}

.recentQuickNoteContainer {
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0,0,0, 0.2);
    margin-top: 10px;

    background: $white-transparent-90;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.recentQuickNoteCardTitle {
    margin-bottom: -5px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.recentQuickNoteCardSubtitle {
    max-width: 100%;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.recentQuickNoteCardTimestamp {
    flex: 0 0 auto;
}



  /* Animation */

  .panel-anim-enter {
    opacity: 0.00;
    transform: scale(0.7);
    transition: all 0.2s;
  }

  .panel-anim-enter.panel-anim-enter-active {
    opacity: 1;
    transform: scale(1);
  }

  .panel-anim-leave {
    opacity: 1;
    transform: scale(1);
    transition: all 0.2s;
  }

  .panel-anim-leave.panel-anim-leave-active {
    opacity: 0.00;
    transform: scale(0.7);
  }

  .flexible-panel-mask {
    position: fixed;
    height: 100%;
    background: rgba(55, 55, 55, 0.6);
    top:0;
    left:0;
    right:0;
    bottom:0;
  }

  .flexible-panel-resizer {
    position:absolute;
    margin:5px;
  }
  .resizer-nw {
    top:-16px;
    left:-16px;
    cursor:nw-resize;
  }
  .resizer-ne {
    top:-16px;
    right:-16px;
    cursor:ne-resize;
  }
  .resizer-se {
      bottom:-16px;
      right:-16px;
      cursor:se-resize;
  }
  .resizer-sw {
    bottom:-16px;
    left:-16px;
    cursor:sw-resize;
  }

  .flexible-panel-drag-area{
    // background: rgba(22, 22, 333, 0.2);
    // border-bottom: solid 1px $black-10;
    height: 50px;
    position:absolute;
    left:0;
    top:0;
    cursor:move;
  }

  .flexible-panel-rebound-btn{
      position: absolute;
      z-index: 1000;
      bottom: 0;
      width: 50px;
      height: 30px;
  }

  .flexible-panel-hidden{
      position: absolute;
      z-index: 1;
      border: 1px solid #ccc;
      background: white;
      animation-fill-mode: forwards;
      -webkit-animation-name: panelHidden; /* Safari 4.0 - 8.0 */
      -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
      animation-name: panelHidden;
      animation-duration: 1s;
  }

  .flexible-panel-rebound-btn-hidden{
      display: none
  }

  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes panelHidden {
      from   {}
      to  {left:0; top:800px; width: 50px; visibility: hidden}
  }

  /* Standard syntax */
  @keyframes panelHidden {
      from   {}
      to  {left:0; top:800px; width: 50px; visibility: hidden}
  }

  .minimise-enter {
      opacity: 0.01;
      transform: scale(0.9) translateY(50%);
  }
  .minimise-enter-active {
      opacity: 1;
      transform: scale(1) translateY(0%);
      transition: all 300ms ease-out;
  }
  .minimise-exit {
      opacity: 1;
      transform: scale(1) translateY(0%);
  }
  .minimise-exit-active {
      opacity: 0.01;
      transform: scale(0.9) translateY(50%);
      transition: all 300ms ease-out;
  }
