@import 'styles/variables';

.inputContainer {
  margin-bottom: 10px;
}

.input {
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  outline: none;
  border: none;
  caret-color: $black-70;
  color: $black-80;
  font-size: 15px;
  line-height: 130%;
  padding-left: 12px;
  padding-right: 12px;
  background: $black-10;
  border-radius: 12px;
}
