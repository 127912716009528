.kv-form, .blob-form {

    .blob-form {
        border: none;
        padding: 0px;
    }

    .form-control {
        border: none;
    }

    .drop-list {
        padding: 0px;
    }
}

.drop-list, .add-type-list {
    min-height: 20px;
    min-width: 200px;
    border-radius: 10px;
    padding: 10px;

    display: block;

}

.drop-list-child, .add-type-item {

    display: inline-flex;
    align-items: center;
    padding: 3px 7px;
    margin: 5px;
    border: 1px solid #A5C5FF;
    border-radius: 5px;

    button {
        float: right;
        background: none;
        border: none;
        padding: 0px;
        margin-left: 5px;
        color: inherit;

    }

    button:hover {
            background-color: transparent;
            color: #A5C5FF;
        }

}

.add-type-list li {
    display: inline-block;
}
