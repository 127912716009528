@import 'styles/variables';

.container {
    width: 405px;
    max-width: 90vw;
    max-height: 596px;
    padding: 24px 29px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    background: #FFFFFF;
    border-radius: 8px;
}

.header {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 56px;
  justify-content: space-between;
  border: 1px solid #ECECEC;
  border-radius: 8px;

  .title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .entityAmountContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
    min-width: 161px;

    .entityAmount {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      color: #949494;
    }

    .sharedEntitiesIconsContainer {
      display: flex;

      .entityIconContainer {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #0000001A;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0px 0.6666702628135681px 2.6666810512542725px 0px #0000000D, 0px 2px 6px 0px #0000000D;        
      }

      .entityIconContainer:not(:first-child) {
        margin-left: -10px;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .subtitle {
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid #E6E6E6;
  }
}



.shareSection {

    &.noDivider {
        border-bottom: unset;
    }

    .selectsContainer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 8px;

        .shareButton {
          width: 60px;
        }
    }

    textarea {
        height: 120px;
        resize: none;
        outline: 0 !important;
        -webkit-appearance: none;
        box-shadow: none !important;
        border: 0.5px solid #E6E6E6;
        border-radius: 4px;
    }

    .shareActionRow {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }
}

.usersSection {
    max-height: 300px;
    overflow-y: auto;
    row-gap: 16px !important;
    padding-top: 16px;
    padding-bottom: 16px;

    .accessLabel {
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        color: #808080;
    }

    .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .userContent {
          display: flex;
          align-items: center;
          column-gap: 8px;
        }

        .userInfo {
          display: flex;
          flex-direction: column;
          margin-right: auto;
          row-gap: 2px;
          max-width: 168px;

          .userName {
            font-size: 13px;
            line-height: 16px;
            font-weight: 600;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

          }

          .userEmail {
            font-size: 9px;
            line-height: 9px;
            font-weight: 500;
            color: #A4A4A4;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
      }

        div:not(:nth-child(2)) {
          flex-shrink: 0;
        }

        .ownerLabel {
            font-weight: 600;
            font-size: 11px;
            line-height: 13px;
            color: #999999;
        }
    }
}

.foundUsersDropdown {
    position: absolute;
    top: 100%;
    width: 470px;
    max-width: 85vw;
    max-height: 240px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 3px;
    padding: 6px;
    overflow: auto;
    background: #FFFFFF;
    box-shadow: 0 9px 24px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    scrollbar-width: thin;
    z-index: 1;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: gray;
        border-radius: 6px;
    }

    .foundUser {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 5px;
        padding: 6px;


        > div:nth-child(1) {
            flex-shrink: 0;
        }

        .userNameEmail {
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            margin-left: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            > div:nth-child(1) {
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
            }

            > div:nth-child(2) {
                font-weight: 400;
                font-size: 12px;
                font-style: italic;
                line-height: 22px;

                &.invalidEmail {
                    color: $error;
                }
            }
        }
    }

    .focusedUser {
        background-color: $selected-80;
        div {
            color: $white !important;
        }
    }
}

.footer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .accessContainer {
    display: flex;
    justify-content: space-between;

    .accessLabel {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .accessText {
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .copyButton {
      width: 85px;
    }

    .doneButton {
      width: 57px;
    }
  }
}

.modalButton {
  height: 32px;
  border: 1px solid #00000026;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 #00000033;
  padding: 8px 12px;
  
  span {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
}

.customAccessWrapper {
  max-height: 355px;
}
