@import './variables.scss';

.activeBorders {
  box-shadow: inset 0 0 0 3px $selected-100;
  background: rgba(40, 160, 240, 0.04);
}

.dropzone {
  @extend .activeBorders;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10;
}
