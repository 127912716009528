@import 'styles/variables';

.toastWrapper {
    z-index: 1001;
    position: fixed;
    bottom: 2rem;
    left: 50%;
    width: 500px;
    max-width: 80vw;
    transform: translateX(-50%);
    min-height: 150px;

    &.hidden {
        display: none;
    }

    &:hover {
        .toastItem {
            position: absolute;
            opacity: 1;

            &:nth-child(2) {
                transform: translate(0, -55px);
            }

            &:nth-child(3) {
                transform: translate(0, -110px);
            }
        }

        .toastItemText {
            visibility: visible;
        }
    }
}
.toastItem {
    z-index: 999;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    color: $black-10;
    font-size: 12px;
    font-weight: 500;
    border-radius: .3rem;
    padding: .3rem 1rem;
    background: rgba(26, 26, 26, 0.8);
    box-shadow:
        0 9px 24px rgba(0, 0, 0, 0.2),
        0 3px 6px rgba(0, 0, 0, 0.1),
        0 0 0 1px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    transition: transform .3s ease-in-out;

    &:nth-child(1) {
        .toastItemText {
            visibility: visible;
        }
    }

    &:nth-child(2) {
        z-index: 998;
        transform: translate(10px, -10px);
        opacity: 0.3;
    }

    &:nth-child(3) {
        z-index: 997;
        transform: translate(20px, -20px);
        opacity: 0.2;
    }

    &:hover {
        .toastCloseButton {
            visibility: visible;
        }
    }
}

.toastItemExpanded {
    composes: toastItem;
    height: auto;
}
.toastIcon {
    transform: translateY(-2px);
}
.toastItemText {
    margin-left: 5px;
    visibility: hidden;
}

.showMore {
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 5px;
    padding: 0 5px;
    transform: translateY(-2px);
    &:hover {
        background-color: rgba(26, 26, 26, 0.5);
    }

    svg {
        path {
            fill: #fff;
        }
    }
}

.toastCloseButton {
    visibility: hidden;
    cursor: pointer;
    position: absolute;
    top: -12px;
    left: -7px;
    svg {
        border-radius: 50%;
        padding: 1px;
        background-color: $white-transparent-75;
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0 2px 6px;
        }
    }
}
.toastUndoButton {
    margin-left: auto;
    span {
        font-size: 12px;
        color: $selected-90;
    }
}
