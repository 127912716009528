.modalWrapper {
    padding: 20px 30px;
    max-width: 500px;
}

.title {
    text-align: center;
}

.infoContainer {
    display: flex;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
